import { useEffect, useMemo, useState } from 'react'

import { useAssets } from 'core/logic/asset/asset.hook'
import { useParams } from 'react-router-dom'
import { useUser } from 'core/logic/user'
import BuyOfferView from './BuyOffer.view'
import { useMarketplace } from 'context/Marketplace'
import { useMarketplaceUser } from 'context/MarketplaceUser'
import WalletRequiredWrapper from 'components/WalletRequiredWrapper'
import ROUTES, { TO_COLLECTION_ITEM_DETAIL } from 'core/modules/router'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'

function BuyOfferLogic() {
  const { assetId, offerId } = useParams<{ assetId: string; offerId: string }>()
  const { assets } = useAssets()
  const history = useCustomHistory()
  const assetToShow = assets?.find((a) => a.id === assetId)

  const user = useUser()
  const completeUser =
    user.detail && user.profile
      ? { ...user.detail, ...user.profile }
      : undefined

  const { sales } = useMarketplace()
  const thisSale = useMemo(() => {
    return sales.items?.[assetId]?.find((s) => s.offerId === offerId)
  }, [sales.items?.[assetId]])
  const [allowance, setAllowance] = useState<number>()

  const {
    balance,
    buyOffer,
    control: buyOfferControl,
    updateBalance,
    getTokensAllowance,
    approveTokenAllowance,
  } = useMarketplaceUser()
  const userBalance = useMemo(() => {
    return thisSale ? balance[thisSale.currency] : undefined
  }, [balance, thisSale])

  const [bought, setBought] = useState(false)

  useEffect(() => {
    if (!sales.items && assetToShow) sales.getNextPage(assetToShow.id)
  }, [sales.items, assetToShow])

  useEffect(() => {
    updateBalance()
  }, [])
  useEffect(() => {
    if (thisSale) getTokensAllowance(thisSale.currency).then(setAllowance)
  }, [thisSale])

  const needsBalanceApproval =
    allowance === undefined ? undefined : !(allowance > 0)

  function routeToItem() {
    history.replace(TO_COLLECTION_ITEM_DETAIL(assetId, assetToShow!.tokenId))
  }

  const _cancel = () =>
    history.goBackWithFallback(ROUTES.unauthenticated.homepage)

  const _approveAllowance = () =>
    approveTokenAllowance(thisSale!.currency!, thisSale!.price!).then(() =>
      getTokensAllowance(thisSale!.currency).then(setAllowance)
    )

  const _retry = () => {
    if (!userBalance) updateBalance()
    else if (!allowance)
      getTokensAllowance(thisSale!.currency).then(setAllowance)
    else if (needsBalanceApproval) _approveAllowance()
    else buyOffer(thisSale!).then(() => setBought(true))
  }

  useEffect(() => {
    updateBalance()
  }, [updateBalance])

  return (
    <BuyOfferView
      key={'buy_result'}
      error={!!buyOfferControl.error}
      onRetry={_retry}
      asset={assetToShow}
      user={completeUser}
      sale={thisSale}
      userBalance={userBalance}
      onConfirm={
        !buyOfferControl.loading
          ? () =>
              !bought
                ? buyOffer(thisSale!).then(() => setBought(true))
                : routeToItem()
          : () => {}
      }
      onCancel={_cancel}
      onApproveAllowance={_approveAllowance}
      loading={buyOfferControl.loading}
      bought={!!bought}
      needsBalanceApproval={needsBalanceApproval}
    />
  )
}

export default function BuyOfferLogicWrapper() {
  return (
    <WalletRequiredWrapper>
      <BuyOfferLogic />
    </WalletRequiredWrapper>
  )
}
