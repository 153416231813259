import Button from '@onepercentio/one-ui/dist/components/Button'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton'
import PaginationIndicator from '@onepercentio/one-ui/dist/components/PaginationIndicator'
import { useOrderableListAnchor } from '@onepercentio/one-ui/dist/components/OrderableList/OrderableList'
import Text from '@onepercentio/one-ui/dist/components/Text'
import usePaginationControls from '@onepercentio/one-ui/dist/hooks/ui/usePaginationControls'
import useElementFit from '@onepercentio/one-ui/dist/hooks/useElementFit'
import useMergeRefs from '@onepercentio/one-ui/dist/hooks/useMergeRefs'
import {
  Paginable,
  useContainerPagination,
  useLocalPagination,
} from '@onepercentio/one-ui/dist/hooks/usePagination'
import {
  createRef,
  ElementRef,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import Container from '../Container'
import Styles from './Section.module.scss'
import { useOneUIConfig } from '@onepercentio/one-ui/dist/context/OneUIProvider'

type SectionProps<T> = {
  className?: string
  ref?: RefObject<HTMLDivElement>
  'data-testid'?: string
  title?: string | ReactElement
  disableHover?: boolean
  seeAll?: {
    onClick: () => void
    label: string
  }
  headingClassName?: string
  itemClassName?: string
  itemContainerClassName?: string
  jsx: {
    empty: ReactElement | null
    loading: ReactElement | null
    factory: (item: T) => ReactElement
    baseWidth: number
  }
  /**
   * Defines how the pagination is calculated
   *
   *      "estimate" // Calculate the amount of pages based on the baseWidth + the amount of items
   *       "dynamic" // Recalculates the pages after each transition (less performance)
   */
  paginationMode?: 'estimate' | 'dynamic'
} & (
  | {
      src: T[] | undefined
    }
  | {
      src: Paginable<T[], []>
    }
)

/**
 * A section container that holds a paginable list of items
 **/
function Section<T>(
  {
    seeAll,
    title,
    src,
    jsx,
    paginationMode = 'estimate',
    disableHover = false,
    className = '',
    ...props
  }: SectionProps<T>,
  _ref: ForwardedRef<HTMLDivElement>
) {
  const paginationRef = useRef<ElementRef<typeof PaginationIndicator>>(null)
  const ref = useMemo(() => _ref || createRef(), [])
  const { itemsToShow = 4, ref: fitRef } = useElementFit(jsx.baseWidth)
  const mainRef = useMergeRefs(ref as RefObject<HTMLDivElement>, fitRef)
  const itemsToShowAndNextPage = itemsToShow
    ? Math.max(Math.floor(itemsToShow * 1.6), itemsToShow + 1)
    : 1
  const { getPage, getNextPage, items, loading, totalItems } =
    src === undefined || Array.isArray(src)
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useLocalPagination(src, itemsToShowAndNextPage)
      : src
  const { scrollableRef } = useContainerPagination(getNextPage, 'h')

  useLayoutEffect(() => {
    ;(scrollableRef as any).current = (mainRef as any).current
  }, [])

  useEffect(() => {
    if (itemsToShow) getPage(0)
  }, [itemsToShow, Array.isArray(src) ? src : undefined])

  const { controls, checkControlsRequirement } = usePaginationControls(mainRef)
  useEffect(() => {
    setTimeout(() => {
      if (paginationRef.current) {
        checkControlsRequirement()
        paginationRef.current?.refreshPages()
      }
    }, 100)
  }, [items?.length])

  useLayoutEffect(() => {
    if (paginationMode === 'dynamic') {
      const onChildElementsResized = (e: TransitionEvent) => {
        if (['width', 'height'].includes(e.propertyName)) {
          checkControlsRequirement()
          paginationRef.current!.refreshPages()
        }
      }
      const onResize = () => {
        checkControlsRequirement()
        paginationRef.current!.refreshPages()
      }
      window.addEventListener('resize', onResize)
      mainRef.current!.addEventListener('transitionend', onChildElementsResized)
      return () => {
        mainRef.current?.removeEventListener(
          'transitionend',
          onChildElementsResized
        )
        window.removeEventListener('resize', onResize)
      }
    }
  }, [paginationMode])

  const state = useMemo(() => {
    if ((!items || items.length === 0) && loading) return jsx.loading
    if (!items) return null
    if (items.length === 0) return jsx.empty
    else
      return items?.map((i) => (
        <div className={`${Styles.item} ${props.itemClassName || ''}`}>
          {' '}
          {jsx.factory(i)}
        </div>
      ))
  }, [items, loading])

  return (
    <SectionView
      itemsRef={mainRef}
      paginationRef={paginationRef}
      loading={loading}
      title={title}
      controls={controls}
      disableHover={disableHover}
      estimatedWidth={
        paginationMode === 'estimate'
          ? (totalItems() || 0) * jsx.baseWidth
          : undefined
      }
      seeAll={seeAll}
      headingClassName={props.headingClassName}
      itemContainerClassName={props.itemContainerClassName}
      className={className}>
      {state}
    </SectionView>
  )
}

export function SectionView<T>({
  title,
  loading,
  seeAll,
  disableHover,
  controls,
  paginationRef,
  itemsRef,
  estimatedWidth,
  children,
  headingClassName = '',
  disableOrdering = false,
  itemContainerClassName = '',
  className,
  ...props
}: PropsWithChildren<
  Pick<
    SectionProps<T>,
    | 'title'
    | 'seeAll'
    | 'disableHover'
    | 'data-testid'
    | 'headingClassName'
    | 'itemContainerClassName'
  > & {
    loading?: boolean
    controls?: ReturnType<typeof usePaginationControls>['controls']

    estimatedWidth?: number
    paginationRef?: RefObject<ElementRef<typeof PaginationIndicator>>
    itemsRef?: RefObject<HTMLDivElement>
    disableOrdering?: boolean
    className?: string
  }
>) {
  const { anchorRef } = useOrderableListAnchor()
  const ref = useMemo(() => itemsRef || createRef<HTMLDivElement>(), [])
  const TitleWrapper = useMemo<
    (p: PropsWithChildren<{}>) => ReactElement
  >(() => {
    if (typeof title === 'string')
      return ({ children }) => <Text type='p20_v2'>{children}</Text>
    else
      return function Wrapper({ children }) {
        const cls = useOneUIConfig('component.text.className.p20_v2')

        return <div className={cls}>{children}</div>
      }
  }, [])
  return (
    <>
      <Container className={`${Styles.root} ${className}`}>
        <div className={`${Styles.heading} ${headingClassName}`}>
          <TitleWrapper>
            <>
              {anchorRef && (
                <div
                  ref={anchorRef}
                  style={{ display: disableOrdering ? 'none' : undefined }}>
                  <MutableHamburgerButton state='default' size={24} />
                </div>
              )}{' '}
              {title} {loading && <Loader />}
            </>
          </TitleWrapper>
          {seeAll && (
            <Button variant='light-outline' onClick={seeAll.onClick}>
              {seeAll.label}
            </Button>
          )}
        </div>
        <div
          className={`${Styles.itemsContainer} ${
            !disableHover ? Styles.hoverable : ''
          }`}>
          {controls}
          <div
            className={`${Styles.items} ${itemContainerClassName}`}
            data-testid={props['data-testid']}
            ref={itemsRef}>
            {children}
          </div>
        </div>
        <div className={Styles.pagination}>
          <PaginationIndicator
            ref={paginationRef}
            scrollableRef={ref}
            size={16}
            estimatedWidth={estimatedWidth}
            onClickPage={(page) => {
              ref.current!.scroll(100 * page, 0)
            }}
          />
        </div>
      </Container>
    </>
  )
}

export default forwardRef<HTMLDivElement, SectionProps<any>>(
  Section
) as typeof Section
