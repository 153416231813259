import { service } from 'core/modules/firebase'
import { TenantType } from './tenant.types'

export const fetchTenant = async (): Promise<TenantType | null> => {
  const doc = await service.fetchTenant()
  const data = doc.data()

  if (!data) return null

  return {
    ...(data as TenantType),
    created: new Date(data.created),
  }
}
