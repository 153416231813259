import { t } from 'translate/i18n'
import { Box, Grid, Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import React, { ComponentProps, useMemo, useState } from 'react'
import useStyles from './CollectionItem.style'
import { currencyNumberFormatter } from 'core/helpers/formatter'
import AssetDisplay from 'openspace/components/AssetDisplay'
import {
  CollectionItemType,
  CollectionItemViewProps,
} from './CollectionItem.types'
import Countdown from '@onepercentio/one-ui/dist/components/Countdown'
import Text from '@onepercentio/one-ui/dist/components/Text'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { PendingPurchase } from 'core/logic/purchase/purchase.types'
import InfoModalView from 'components/InfoModal/InfoModal.view'
import ActionButtonView from 'components/ActionButton/ActionButton.view'
import BackButtonView from 'components/BackButton/BackButton.view'
import { AssetType } from 'core/logic/asset/asset.types'

const Actions = ({
  onContinue,
  onBack,
}: {
  onContinue: () => void
  onBack: () => void
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <ActionButtonView
        text={t('authenticated.myCollection.open')}
        onClick={onContinue}
      />
      <BackButtonView
        icon='close'
        text={t('authenticated.myCollection.cancel')}
        onClick={onBack}
      />
    </div>
  )
}

const CollectionItemView: React.FC<CollectionItemViewProps> = ({
  id,
  onClick,
  itemName,
  itemCount,
  itemAnimation,
  orientation,
  itemDescription,
  offerDetails,
  asset,
  expiration,
  ...props
}) => {
  const { type } = props
  const classes = useStyles()

  const heroId =
    props.type === CollectionItemType.DELIVERY
      ? props.delivery.id
      : props.type === CollectionItemType.PURCHASE
      ? props.purchase.id
      : undefined

  const truncateString = (string?: string) => {
    return string ? string.replace(/(.{150})..+/, '$1...') : ''
  }

  const timeRemaining = useMemo(() => {
    if (expiration) return expiration.getTime() - Date.now()
  }, [expiration])

  const { heroRef } = useHero(
    `collection-item-${asset?.id}-${asset?.tokenId ?? 'default'}-${heroId}`,
    undefined,
    {
      onHeroSkip: () => false,
      onHeroDetect: () => {
        return !document.querySelectorAll(
          '[data-orderableid="orderable-list-clone"]'
        ).length
      },
    }
  )

  const [selectedDelivery, setSelectedDelivery] = useState(false)

  const _onClickItem = () => {
    if (onClick) {
      switch (type) {
        case CollectionItemType.DELIVERY:
          setSelectedDelivery(true)
          break
        default:
          onClick(id || '')
      }
    }
  }

  const assetToDisplay = useMemo<
    ComponentProps<typeof AssetDisplay>['asset']
  >(() => {
    if (props.type === CollectionItemType.DELIVERY) {
      if (props.delivery.type === 'airdrop') {
        const asset = props.delivery.asset!
        return {
          id: asset.id,
          tokenId: asset.tokenId,
          media: asset.media,
        }
      } else {
        const offer = props.delivery.offer
        return {
          id: offer.id,
          tokenId: 0,
          media: { coverUrl: offer.image } as AssetType['media'],
        }
      }
    } else if (props.type === CollectionItemType.PURCHASE) {
      const offer = props.purchase.offer
      return {
        id: offer.id,
        tokenId: 0,
        media: { coverUrl: offer.image } as AssetType['media'],
      }
    } else {
      return asset!
    }
  }, [props.type, asset])

  return (
    <>
      <Box
        {...({
          ref: heroRef,
        } as any)}
        display='flex'
        flexDirection='column'
        className={clsx(
          classes.container,
          clsx(
            classes.pointer,
            orientation === 'vertical'
              ? classes.containerVertical
              : classes.containerHorizontal
          )
        )}
        onClick={!itemAnimation ? _onClickItem : undefined}>
        <Grid container item>
          <AssetDisplay asset={assetToDisplay} uniqueId={heroId} />
        </Grid>
        {offerDetails && (
          <Box mt={3} onClick={onClick ? () => onClick(id || '') : undefined}>
            <Chip
              label={t('authenticated.myCollection.collectionItem.active')}
              variant='outlined'
              className={classes.chip}
            />
          </Box>
        )}
        <Grid
          container
          item
          className={classes.descriptionContainer}
          onClick={
            onClick
              ? (e) => {
                  e.stopPropagation()
                  _onClickItem()
                }
              : undefined
          }>
          <Box
            display={'flex'}
            className={clsx(
              orientation === 'horizontal'
                ? classes.descriptionBoxPadding
                : null,
              classes.descriptionBox
            )}>
            <Typography variant='h4' color='textPrimary'>
              {itemName}
            </Typography>
          </Box>

          <Typography
            variant='body2'
            color='textSecondary'
            className={classes.description}>
            {truncateString(itemDescription)}
          </Typography>
          {itemCount && (
            <Box>
              <Chip
                label={t('authenticated.myCollection.collectionItem.unitsNew', {
                  count: offerDetails?.quantity || itemCount,
                })}
                color='primary'
                className={classes.chip}
              />
            </Box>
          )}
          {expiration && (
            <Box>
              <Chip
                label={
                  <>
                    {t('authenticated.myCollection.timeLeft')}{' '}
                    <Countdown
                      timeRemaining={timeRemaining!}
                      options={{ hours: false }}
                    />
                  </>
                }
                color='primary'
                className={classes.chip}
              />
            </Box>
          )}
          {offerDetails && (
            <Grid
              container
              alignItems='center'
              justifyContent='space-between'
              wrap='nowrap'>
              <Grid item className={classes.valueLabel}>
                <Typography variant='body1' noWrap>
                  {t('authenticated.myCollection.collectionItem.announcePrice')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant='body1'
                  align='right'
                  className={classes.price}>
                  {offerDetails.currency}{' '}
                  <b>{currencyNumberFormatter(offerDetails.price)}</b>
                </Typography>
              </Grid>
            </Grid>
          )}
          {type === CollectionItemType.DELIVERY && (
            <Text type={FigmaTypo.P12}>
              {props.delivery.type === 'airdrop'
                ? t('authenticated.myCollection.clickToClaim')
                : t('authenticated.myCollection.clickToOpen')}
            </Text>
          )}
          {type === CollectionItemType.PURCHASE && (
            <CountdownLabel purchase={props.purchase} />
          )}
        </Grid>
      </Box>
      <InfoModalView
        title={t('authenticated.myCollection.open')}
        open={selectedDelivery}
        handleClose={() => setSelectedDelivery(false)}
        content={
          type === CollectionItemType.DELIVERY &&
          props.delivery.type === 'airdrop'
            ? t('authenticated.myCollection.wantToProceedWithClaim')
            : t('authenticated.myCollection.wantToProceed')
        }
        actions={
          <Actions
            onContinue={() => onClick!(id)}
            onBack={() => setSelectedDelivery(false)}
          />
        }
      />
    </>
  )
}

function CountdownLabel({ purchase }: { purchase: PendingPurchase }) {
  const timeRemaining = useMemo(() => {
    return purchase.expireAt.getTime() - Date.now()
  }, [purchase])

  return (
    <>
      <Text type={FigmaTypo.P12}>
        {t('authenticated.myCollection.proceed')}{' '}
      </Text>
      <Text type={FigmaTypo.P12}>
        {t('authenticated.myCollection.timeLeft')}{' '}
        <Countdown timeRemaining={timeRemaining!} options={{ hours: false }} />
      </Text>
    </>
  )
}

export default CollectionItemView
