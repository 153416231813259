import { JUNO_LIB_URL } from 'core/constants'
import { Helmet } from 'react-helmet'

const JunoHashScript: React.FC = () => {
  return (
    <Helmet>
      <script src={JUNO_LIB_URL} type='text/javascript' />
    </Helmet>
  )
}

export default JunoHashScript
