import React, {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { AllGalleriesViewProps } from './AllGalleries.types'
import Styles from './AllGalleries.module.scss'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { tO } from 'translate/i18n'
import Section from 'openspace/components/Section'
import {
  useContainerPagination,
  useLocalPagination,
} from '@onepercentio/one-ui/dist/hooks/usePagination'
import GalleryCard, { BaseWidth } from 'openspace/components/GalleryCard'
import Container from 'openspace/components/Container'
import useElementFit from '@onepercentio/one-ui/dist/hooks/useElementFit'
import useMergeRefs from '@onepercentio/one-ui/dist/hooks/useMergeRefs'
import TogglableTags from 'openspace/components/TogglableTags'
import OrderableList from '@onepercentio/one-ui/dist/components/OrderableList'
import { OrderableListReorderMode } from '@onepercentio/one-ui/dist/components/OrderableList/OrderableList'
import { BASE_EXPANDED_GALLERY_WIDTH } from 'openspace/components/GalleryCard/GalleryCard.logic'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import BottomPaginationControls from 'openspace/components/BottomPaginationControls'
import SectionLoader from 'openspace/components/SectionLoader/SectionLoader'

function TogglableGalleryList({ galleries }: { galleries: GalleryType[] }) {
  const [currentGallery, setCurrentGallery] = useState(galleries[0])

  const currentPage = useMemo(() => {
    return galleries.indexOf(currentGallery) + 1
  }, [galleries, currentGallery])

  return (
    <>
      <UncontrolledTransition
        transitionType={TransitionAnimationTypes.COIN_FLIP}
        lockTransitionWidth
        lockTransitionHeight>
        <GalleryCard
          key={currentGallery.id}
          gallery={currentGallery}
          shouldExpandOnMount
          mode='highlight'
        />
      </UncontrolledTransition>
      <BottomPaginationControls
        currentPage={currentPage}
        onPageClick={(page) => {
          setCurrentGallery(galleries[page - 1])
        }}
        pages={galleries.length}
      />
    </>
  )
}

/**
 * Show all the galleries
 **/
export default function AllGalleriesView({
  galleries,
  filter,
}: AllGalleriesViewProps) {
  const highlightedGalleries = useMemo(
    () => galleries?.filter((g) => g.highlight) || [],
    [galleries]
  )
  const { itemsToShow, ref } = useElementFit(BaseWidth)
  const {
    getNextPage,
    getPage,
    items: galleriesPaginated,
  } = useLocalPagination(
    filter.filteredGalleries || [],
    itemsToShow ? itemsToShow * 3 : 0
  )
  const { scrollableRef } = useContainerPagination(getNextPage)
  useEffect(() => getPage(0), [filter.filteredGalleries, itemsToShow])
  useLayoutEffect(() => {
    ;(scrollableRef as MutableRefObject<HTMLDivElement>).current =
      mainRef.current!.parentElement!.parentElement! as HTMLDivElement
  })
  const mainRef = useMergeRefs(ref)

  return (
    <>
      <Container>
        <Text className={Styles.title} type={FigmaTypo.H1}>
          {tO('allGalleries.title')}
        </Text>
      </Container>
      <Section
        title={tO('allGalleries.sections.highlight.title')}
        src={[highlightedGalleries]}
        disableHover={true}
        itemClassName={Styles.galleryContainer}
        itemContainerClassName={Styles.items}
        jsx={{
          empty: null,
          loading: null,
          baseWidth: BASE_EXPANDED_GALLERY_WIDTH,
          factory: (g) => (
            <Container className={Styles.perspective}>
              {g.length ? <TogglableGalleryList galleries={g} /> : <></>}
            </Container>
          ),
        }}
        headingClassName={Styles.sectionTitle}
        paginationMode='dynamic'
      />
      <Container>
        <TogglableTags
          tags={filter.categories.map((c) => ({
            active: c === filter.selectedCategory,
            label: c,
            id: c,
          }))}
          onClick={(c) => filter.onSelectCategory(c)}
        />
      </Container>
      <Container className={Styles.galleriesList} ref={mainRef}>
        {galleries && galleriesPaginated ? (
          <OrderableList
            currentOrder={galleriesPaginated.map((a) => a.id)}
            mode={OrderableListReorderMode.TWO_DIMENSIONS}
            className={Styles.orderableGalleries}>
            {galleriesPaginated.map((a) => (
              <GalleryCard
                key={a.id}
                shouldExpandOnMount={false}
                gallery={a}
                mode='default'
                className={Styles.card}
              />
            ))}
          </OrderableList>
        ) : (
          <SectionLoader />
        )}
      </Container>
    </>
  )
}
