import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import {
  ChallengeClaimProcess,
  ChallengeDTO,
  ChallengeGalleryApproval,
} from 'core/logic/challenges/challenges.types'
import {
  approveContract,
  fetchChallenges,
} from 'core/modules/firebase/service'
import {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useAssetsBy } from './Asset'
import { useContextControl } from '@onepercentio/one-ui/dist/context/ContextAsyncControl'
import useImmediate from '@onepercentio/one-ui/dist/hooks/utility/useImmediate'
import { useBalanceForAssets } from './MyCollection'
import useRouteGalleryOrDefault from 'openspace/hooks/useRoutePathGallery'
import useWeb3Provider from 'hooks/useWeb3Provider'
import { CHALLENGE_ABI } from 'core/constants/ChallengesABI'
import { useUser } from 'core/logic/user'
import {
  useContractForGalleries,
  useContracts,
  useGalleriesForAssets,
} from './Gallery'
import { ContractType } from 'core/logic/contract/types/types'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import axios from 'axios'
import { dispatchAndWait } from 'core/helpers/contract'
import useLanguage from 'openspace/hooks/useLanguage'
import { fromWei, toWei } from 'web3-utils'
import { ChallengeState } from 'openspace/pages/Unauthenticated/ChallengeClaim/ChallengeClaim.types'
import {
  useIsWalletCustody,
  useWalletOwnershipOperation,
} from './Wallet'
import { claimChallenge as claimChallengeFunction } from 'core/modules/firebase/service'
import Challenge from 'models/Challenge'
import { ChainConfig } from 'core/constants'
import { useChain } from './Chain'
import {
  useOkenOrProviderTransactionProcess,
} from './ProcessPool'
import { selectBasedOnError } from 'utility/condition'

export type ChallengeContextShape = {
  challenges: Challenge[] | undefined
  loadChallenges: (challengeIds?: string[]) => Promise<void>
  claimChallenge: WalletOwnershipBasedOperation<
    (
      challenge: Challenge,
      loyaltyTokenId?: number
    ) => Promise<OkenIdOrMiningHash>
  >
  contract: Contract.Challenge
}
export const ChallengeContext = createContext<ChallengeContextShape>(
  null as any
)

export default function ChallengeProvider({ children }: PropsWithChildren<{}>) {
  const { profile } = useUser()
  const [allChallengesDTO, setChallenges] = useState<ChallengeDTO[]>()
  const chain = useChain()

  const loadChallenges = useCallback<ChallengeContextShape['loadChallenges']>(
    async (challengeIds) => {
      const challenges = await fetchChallenges(challengeIds)
      setChallenges((prev = []) => [...prev, ...challenges])
    },
    []
  )

  const language = useLanguage()
  const parsedChallenges = useMemo(() => {
    return allChallengesDTO?.map((dto) =>
      Challenge.fromChallengeDTO(dto, language)
    )
  }, [allChallengesDTO, language])

  const web3 = useWeb3Provider()
  const tenantOrGallery = useRouteGalleryOrDefault()
  const challengeContract = useMemo(() => {
    if (!web3 || !tenantOrGallery.challenges?.address) return
    return new web3.eth.Contract(
      CHALLENGE_ABI,
      tenantOrGallery?.challenges?.address
    ) as unknown as Contract.Challenge
  }, [tenantOrGallery, web3])

  const claimChallenge = useWalletOwnershipOperation<
    ChallengeContextShape['claimChallenge']
  >({
    async custody(challenge, loyaltyTokenId) {
      return claimChallengeFunction(challenge, loyaltyTokenId)
    },
    async provider(challenge, loyaltyTokenId = 0) {
      return await dispatchAndWait(
        challengeContract!.methods
          .complete(challenge.recipeId, loyaltyTokenId.toString())
          .send({ from: profile!.wallet!, ...(await _estimateGasFee(chain!)) })
      )
    },
  })

  return (
    <ChallengeContext.Provider
      value={{
        challenges: parsedChallenges,
        loadChallenges: loadChallenges,
        claimChallenge,
        contract: challengeContract!,
      }}>
      {children}
    </ChallengeContext.Provider>
  )
}

export function useChallenges() {
  const { challenges, loadChallenges } = useContext(ChallengeContext)
  const control = useContextControl('challenges-load', { loadChallenges })
  useImmediate(() => {
    if (challenges === undefined && !control.loading) control.loadChallenges()
  }, [])

  return {
    challenges,
    loading: control.loading,
    error: control.error,
    retry: control.loadChallenges,
  }
}

export function useHighlightedChallenges(galleryId?: string) {
  const { challenges: allChallenges, ...control } = useChallenges()
  const highlightChallenges = allChallenges?.filter((challenge) => {
    if (galleryId) return challenge.galleryId === galleryId
    else return challenge.highlight
  })
  return {
    challenges: highlightChallenges,
    ...control,
  }
}

export function useChallenge(challengeId: string) {
  const { challenges, loadChallenges } = useContext(ChallengeContext)
  const control = useContextControl(`load-challenge-${challengeId}`, {
    loadChallenges,
  })

  const foundChallenge = useMemo(() => {
    return challenges?.find((c) => c.id === challengeId)
  }, [challenges, challengeId])

  useEffect(() => {
    if (foundChallenge === undefined && !control.loading)
      control.loadChallenges([challengeId])
  }, [challengeId])

  return {
    challenge: foundChallenge,
    loading: control.loading,
    error: control.error,
    retry: control.loadChallenges,
  }
}

export function useChallengeAssets(challenge: Challenge) {
  const assetsForChallenge = useMemo(() => {
    const assetIds = challenge.requirements.map((a) => a.assetId)
    return assetIds
  }, [challenge])
  const { allAssetsLoaded, assets, retry, loading, error } = useAssetsBy(
    'id',
    assetsForChallenge
  )

  return {
    loadedAllAssets: allAssetsLoaded,
    assets: assets,
    loading: loading,
    error: error,
    retry: retry,
  }
}

export function useChallengeBalances(challenge: Challenge) {
  const assets = useChallengeAssets(challenge).assets

  return useBalanceForAssets(assets)
}

export function useChallengeClaim(challenge: Challenge) {
  const { claimChallenge } = useContext(ChallengeContext)
  const { assets } = useChallengeAssets(challenge)
  const { retry: reloadAssetsBalance, assetsWithBalance } =
    useBalanceForAssets(assets)
  const loyaltyToken = useMemo(() => {
    const loyaltyAsset = assetsWithBalance.find((a) => a.tokenIds)
    if (loyaltyAsset) return loyaltyAsset.tokenIds![0]
  }, [assetsWithBalance])
  const transactionMinedControl =
    useOkenOrProviderTransactionProcess<ChallengeClaimProcess>(
      `challenge_claim_${challenge.id}`,
      async () => {
        await reloadAssetsBalance()
      }
    )
  const claimControl = useContextControl(`challenge-${challenge.id}-claim`, {
    claimChallenge: () =>
      claimChallenge(challenge, loyaltyToken).then((okenTxIdOrMiningHash) => {
        transactionMinedControl.trigger(okenTxIdOrMiningHash)
      }),
  })

  return {
    ...claimControl,
    loading: claimControl.loading || transactionMinedControl.loading,
    error: claimControl.error || transactionMinedControl.error,
    retry:
      claimControl.claimChallenge ||
      (() => {
        transactionMinedControl.setError(undefined)
      }),
  }
}

async function _estimateGasFee(chain: ChainConfig) {
  if (process.env.AUTOMATION)
    return {
      gas: 90000000,
      gasPrice: '90000000000',
    }
  const fees = await axios.get<{
    estimatedBaseFee: number
    standard: {
      maxPriorityFee: number
      maxFee: number
    }
  }>(chain.gasFeeEndpoint!)
  const estimatedMaxGassFee = Math.trunc(
    fees.data.standard.maxPriorityFee * 1e9
  )

  return { maxPriorityFeePerGas: estimatedMaxGassFee }
}

/**
 * Returns the authorization state for each contract consumed by challenge
 */
export function useAuthorizations(challenge: Challenge) {
  const { contract: challengeContract } = useContext(ChallengeContext)
  const { profile } = useUser()
  const { assets: challengeAssets, loadedAllAssets } =
    useChallengeAssets(challenge)
  const { loadedAllGalleries, galleries } =
    useGalleriesForAssets(challengeAssets)
  const galleriesWithContract = useContractForGalleries(galleries!)

  const [approvals, setApprovals] = useState<{
    [galleryId: string]: boolean | undefined
  }>()

  const transactionMinedControl =
    useOkenOrProviderTransactionProcess<ChallengeGalleryApproval>(
      `challenge_gallery_approval_${challenge.id}`,
      async () => await checkApprovals()
    )

  const checkApprovals = useCallback(async () => {
    const galleriesWithoutApproval = galleriesWithContract.filter(
      (g) => approvals?.[g.id] === undefined
    )
    await Promise.all(
      galleriesWithoutApproval.map(async ({ contract, smartContract, id }) => {
        switch (smartContract!.type) {
          case ContractType.RarumNFT:
          case ContractType.Loyalty:
            const approvalBasedContract = contract as
              | Contract.ERC1155
              | Contract.ERC721
            const isApproved = await approvalBasedContract.methods
              .isApprovedForAll(
                profile!.wallet!,
                challengeContract.options.address
              )
              .call()
            return setApprovals((prev) => ({ ...prev, [id]: isApproved }))
          case ContractType.Fungible:
            const requiredAllowance = challenge.requirements.find(
              (r) =>
                r.assetId ===
                challengeAssets.find((a) => a.galleryId === id)!.id
            )!.amount
            const allowanceBasedContract = contract as Contract.IERC20
            const authorizedAllowance = await allowanceBasedContract.methods
              .allowance(profile!.wallet!, challengeContract.options.address)
              .call()
            const isAllowanceEnough =
              Number(fromWei(authorizedAllowance)) >= requiredAllowance
            setApprovals((prev) => ({ ...prev, [id]: isAllowanceEnough }))
        }
      })
    )
  }, [galleriesWithContract])

  const checkApprovalsControl = useAsyncControl({
    checkApprovals,
  })

  useEffect(() => {
    if (
      loadedAllAssets &&
      loadedAllGalleries &&
      !approvals &&
      !checkApprovalsControl.loading
    )
      checkApprovalsControl.checkApprovals()
  }, [approvals, galleriesWithContract, loadedAllGalleries, loadedAllAssets])

  const loadedAllApprovals = useMemo(() => {
    return (
      approvals &&
      Object.keys(approvals).length === galleriesWithContract.length
    )
  }, [galleriesWithContract, approvals])

  const galleriesWithoutAuthorization = useMemo(() => {
    return galleriesWithContract.filter((g) => approvals?.[g.id] === false)
  }, [galleriesWithContract, Object.keys(approvals || {}).length])

  const galleriesToAuthorize = useMemo(() => {
    return galleriesWithoutAuthorization.map((g) => ({
      ...g,
      approved: !!approvals![g.id],
    }))
  }, [galleriesWithoutAuthorization, approvals])

  const galleryApprovalControl = useGalleryApproval(challenge)

  return {
    loading: checkApprovalsControl.loading || transactionMinedControl.loading,
    error: checkApprovalsControl.error || transactionMinedControl.error,
    retry: selectBasedOnError(
      [checkApprovalsControl.error, checkApprovalsControl.checkApprovals],
      [
        transactionMinedControl.error,
        () => transactionMinedControl.setError(undefined),
      ]
    ),
    galleriesToAuthorize,
    loadedAllApprovals,
    approve: (g: GalleryType) =>
      galleryApprovalControl
        .approve(g)
        .then((okenTxIdOrMiningHash) =>
          transactionMinedControl.trigger(okenTxIdOrMiningHash)
        ),
  }
}

export function useGalleryApproval(challenge: Challenge) {
  const chain = useChain()
  const { contract: challengeContract } = useContext(ChallengeContext)
  const { assets } = useChallengeAssets(challenge)
  const { galleries } = useGalleriesForAssets(assets)
  const { profile } = useUser()
  const contracts = useContracts()
  const isCustodyWallet = useIsWalletCustody()
  const approve = useMemo(() => {
    if (isCustodyWallet)
      return async ({ id }: GalleryType) =>
        await approveContract(id, challengeContract.options.address, true)
    else
      return async ({ smartContract }: GalleryType) => {
        const contract = contracts[smartContract!.address!]
        switch (smartContract!.type) {
          case ContractType.RarumNFT:
          case ContractType.Loyalty:
            const approvalBasedContract = contract as
              | Contract.ERC1155
              | Contract.ERC721
            return dispatchAndWait(
              approvalBasedContract.methods
                .setApprovalForAll(
                  challengeContract.options.address as any,
                  true as any
                )
                .send({
                  from: profile!.wallet!,
                  ...(await _estimateGasFee(chain!)),
                })
            )
          case ContractType.Fungible:
            const allowanceBasedContract = contract as Contract.IERC20
            const galleryForThisContract = galleries!.find(
              (g) =>
                g.smartContract!.address.toLowerCase() ===
                allowanceBasedContract.options.address.toLowerCase()
            )!
            const assetForThisGallery = assets.find(
              (a) => a.galleryId === galleryForThisContract.id
            )!
            const requiredAllowance = challenge.requirements.find(
              (r) => r.assetId === assetForThisGallery.id
            )!.amount
            return dispatchAndWait(
              allowanceBasedContract.methods
                .approve(
                  challengeContract.options.address,
                  toWei(requiredAllowance.toString())
                )
                .send({
                  from: profile!.wallet!,
                  ..._estimateGasFee(chain!),
                })
            )
        }
      }
  }, [contracts, galleries, assets])
  const control = useContextControl('gallery_allowance_approval', { approve })
  return { ...control }
}

export function useChallengeClaimState(challenge: Challenge) {
  const { isLoggedIn, profile } = useUser()
  const assetsControl = useChallengeAssets(challenge)
  const balancesControl = useChallengeBalances(challenge)
  const claimChallenge = useChallengeClaim(challenge)
  const challengClaimState = useMemo<ChallengeState>(() => {
    if (assetsControl.loadedAllAssets && (!isLoggedIn || !profile?.wallet))
      return 'readonly'

    const isMissingSomeRequiredAsset = challenge.requirements.some(
      (req) =>
        !balancesControl.assetsWithBalance.find((a) => a.id === req.assetId)
    )

    if (assetsControl.error || balancesControl.error || claimChallenge.error)
      return 'error'
    if (claimChallenge.loading) return 'claiming'
    if (
      assetsControl.loading ||
      balancesControl.loading ||
      isMissingSomeRequiredAsset
    )
      return 'loading'

    const isMissingSomeRequirement = challenge.requirements.some((req) => {
      const asset = balancesControl.assetsWithBalance.find(
        (a) => a.id === req.assetId
      )!
      return asset.balance! < req.amount
    })
    if (isMissingSomeRequirement) return 'unavailable'
    else return 'claimable'
  }, [
    isLoggedIn,
    assetsControl.loading,
    assetsControl.error,
    balancesControl.loading,
    balancesControl.error,
    claimChallenge.loading,
    claimChallenge.error,
    balancesControl.assetsWithBalance,
  ])

  return challengClaimState
}
