import React, { PropsWithChildren, useMemo } from 'react'
import Styles from './Tag.styles'

/**
 * A simple tag
 **/
export default function Tag({
  children,
  color = 'default',
  size = 'normal',
  className = '',
}: PropsWithChildren<{
  color?: 'default' | 'primary'
  size?: 'normal' | 'small'
  className?: string
}>) {
  const extra = useMemo(() => {
    if (color === 'primary') return 'dark'
    return ''
  }, [])
  return (
    <div
      className={`${Styles.root} ${Styles[color]} ${extra} ${Styles[size]} ${className}`}>
      {children}
    </div>
  )
}
