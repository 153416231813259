import PaginationIndicator from '@onepercentio/one-ui/dist/components/PaginationIndicator'
import usePaginationControls from '@onepercentio/one-ui/dist/hooks/ui/usePaginationControls'
import useMergeRefs from '@onepercentio/one-ui/dist/hooks/useMergeRefs'
import {
  Paginable,
  useContainerPagination,
} from '@onepercentio/one-ui/dist/hooks/usePagination'
import {
  createRef,
  ForwardedRef,
  forwardRef,
  ReactElement,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Styles from './PaginableContainer.module.scss'

/**
 * A generic paginable container
 **/
function PaginableContainer<T>(
  {
    src,
    jsx,
  }: {
    src: Paginable<T[]>
    jsx: {
      width: number
      factory: (item: T) => ReactElement
    }
    ref?: RefObject<HTMLDivElement>
  },
  ref: ForwardedRef<HTMLDivElement>
) {
  const _ref = useMemo(
    () => (ref as RefObject<HTMLDivElement>) || createRef<HTMLDivElement>(),
    []
  )
  const { scrollableRef } = useContainerPagination(src.getNextPage, 'h')
  const mainRef = useMergeRefs(scrollableRef, _ref)
  const [showPaginable, setShowPaginable] = useState(false)
  const { checkControlsRequirement, controls } = usePaginationControls(
    scrollableRef,
    {
      snapToCutElement: true,
      baseWidth: jsx.width,
    }
  )

  useEffect(() => {
    const parentEl =
      scrollableRef.current!.parentElement!.parentElement!.parentElement!

    if (parentEl.clientWidth > 100) {
      setShowPaginable(true)
    } else {
      const func = () => {
        setShowPaginable(true)
        parentEl.removeEventListener('transitionend', func)
      }
      scrollableRef.current!.parentElement!.parentElement!.parentElement!.addEventListener(
        'transitionend',
        func
      )
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkControlsRequirement()
    }, 100)
    return () => clearTimeout(timeout)
  }, [showPaginable, src.totalItems()])

  return (
    <>
      <div className={Styles.parentRoot}>
        {controls}
        <div ref={mainRef} className={Styles.root}>
          {src.items?.map(jsx.factory)}
        </div>
        {showPaginable ? (
          <PaginationIndicator
            className={Styles.paginable}
            scrollableRef={mainRef}
            size={16}
            estimatedWidth={(src.totalItems() || 0) * jsx.width}
          />
        ) : (
          <span style={{ fontSize: 16 }}>&nbsp;</span>
        )}
      </div>
    </>
  )
}

export default forwardRef(PaginableContainer) as typeof PaginableContainer
