import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardContent: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    divider: {
      backgroundColor: theme.palette.grey[200],
      alignSelf: 'stretch',
      height: 2,
    },
  })

export default makeStylesWrapper(style)
