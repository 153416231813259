import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ActionButton from 'components/ActionButton'
import useStyles from './EmptyCollection.style'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'

interface EmptyCollectionViewProps {
  actionName?: string
  action?: () => void
  'data-testid'?: string
}
const EmptyCollectionView: React.FC<EmptyCollectionViewProps> = ({
  actionName,
  action,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      pl={3}
      pr={3}
      data-testid={props['data-testid']}>
      <Box className={classes.figure} />
      <Typography
        variant='body1'
        component='span'
        color='textSecondary'
        align='center'
        className={classes.heading}>
        <strong>
          {i18n.t('authenticated.myCollection.emptyCollection.empty')}
        </strong>
      </Typography>
      <Spacing size='16'/>
      {action && actionName && (
        <ActionButton
          text={actionName}
          onClick={action}
          className={classes.button}
        />
      )}
    </Box>
  )
}

export default EmptyCollectionView
