import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import usePooledOperation from '@onepercentio/one-ui/dist/hooks/usePooledOperation'
import {
  ZERO_ADDRESS,
  DELIVERY_TYPE,
  RARUMSALESABI,
  STABLE_TOKENS,
  SIGNATURE_TTL_SECONDS,
} from 'core/constants'
import { Sale } from 'core/logic/asset/asset.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { useStableBalance } from 'core/logic/token/token.hook'
import { useUser } from 'core/logic/user'
import {
  dispatchAndWait,
  normalizePrice,
  normalizeUnit,
  sendAndWaitForConfirmation,
  waitForConfirmation,
} from 'core/helpers/contract'
import {
  OfferForm,
  PREDEFINED_DEADLINES,
} from 'pages/Authenticated/Marketplace/CreateOffer/CreateOffer.types'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useMarketplace } from './Marketplace'
import { convertToInteger } from 'core/helpers/convertNumbers'
import {
  doCreateSignedOffer,
  doForwardApproval,
  doUpdateWallet,
  observeOkenTransaction,
} from 'core/modules/firebase/service'

import { useSignMessage } from 'core/logic/wallet'
import { ERC1155ABI } from 'core/constants/ERC1155ABI'
import { OfferType } from 'core/logic/drop/drop.types'
import useRouteGalleryOrDefault from 'openspace/hooks/useRoutePathGallery'
import useWeb3Provider from 'hooks/useWeb3Provider'
import useWeb3Utils from 'hooks/useWeb3Utils'
import { estimateGasFeeFromEnv } from 'utility/gas'
import { useChain } from './Chain'

export type MarketplaceUserContextShape = {
  balance: {
    [currency: string]: number | undefined
  }
  updateBalance: () => void

  updateWallet: (wallet: string) => Promise<void>
  buyOffer: (offerId: Sale) => Promise<void>

  createOffer: (offer: OfferForm) => Promise<Sale['offerId']>
  removeOffer: (offerId: string) => Promise<Sale['offerId']>
  updateOffer: (offerId: string, offer: OfferForm) => Promise<Sale['offerId']>
  operationControl: Omit<ReturnType<typeof usePooledOperation>, 'start'>
  getNftMovementAllowance: () => Promise<boolean>
  approveNFTManagement: () => Promise<true>
  createOfferOnly: (offer: OfferForm) => Promise<OfferType['id']>

  getTokensAllowance: (token: string) => Promise<number>
  approveTokenAllowance: (token: string, basePrice: number) => Promise<void>
}

export const Context = createContext<MarketplaceUserContextShape>(null as any)

export async function isApprovedNftManagement(
  salesContract: Contract.ERC1155,
  fromWallet: string,
  byOperator: string
) {
  return await salesContract.methods
    .isApprovedForAll(fromWallet, byOperator)
    .call()
}
export function MarketplaceUserProvider({ children }: PropsWithChildren<{}>) {
  const chain = useChain()
  const web3Utils = useWeb3Utils()
  const gallery = useRouteGalleryOrDefault()
  const { profile } = useUser()
  const marketplaceContext = useMarketplace()
  const { tenant } = useTenant()
  const { CurrencyContracts, currencyDetails } = useMarketplace()

  const web3MetamaskProvider = useWeb3Provider()

  const { stableBalance: currencyBalanceMap, updateBalance } = useStableBalance(
    profile?.wallet
  )

  const signMessageHook = useSignMessage()
  const polledHook = useAsyncControl()
  const nftContractDefinition = gallery
  const salesContract = useMemo(() => {
    if (!web3MetamaskProvider) return
    return tenant && tenant.sales?.polygon
      ? (new web3MetamaskProvider.eth.Contract(
          RARUMSALESABI,
          tenant.sales!.polygon
        ) as unknown as Contract.Sales)
      : undefined
  }, [tenant, web3MetamaskProvider])

  const erc1155Contract = useMemo(() => {
    if (!web3MetamaskProvider) return
    return nftContractDefinition && nftContractDefinition.smartContract?.address
      ? (new web3MetamaskProvider.eth.Contract(
          ERC1155ABI,
          nftContractDefinition.smartContract.address
        ) as unknown as Contract.ERC1155)
      : null
  }, [nftContractDefinition, web3MetamaskProvider])

  const getAllowance = async (
    contract: typeof CurrencyContracts[CryptoCurrencySymbol]
  ) => {
    const permissionedAllowanceWei = await contract.methods
      .allowance(profile!.wallet!, tenant!.sales!.polygon)
      .call()
    const permissionedAllowance = normalizePrice(
      Number(permissionedAllowanceWei),
      STABLE_TOKENS.find(
        (a) => a.address === (contract as any).options.address
      )!.decimals
    )

    return permissionedAllowance
  }
  const checkAllowance = async (
    currency: CryptoCurrencySymbol,
    price: number,
    estimatedMaxGassFee: number
  ) => {
    const contract = CurrencyContracts[currency]
    const permissionedAllowance = await getAllowance(contract)

    if (permissionedAllowance < price) {
      await dispatchAndWait(
        contract.methods
          .approve(
            tenant!.sales!.polygon,
            '1000' +
              ''.padEnd(
                STABLE_TOKENS.find(
                  (a) => a.address === (contract as any).options.address
                )!.decimals,
                '0'
              )
          )
          .send({
            from: profile!.wallet!,
            maxPriorityFeePerGas: estimatedMaxGassFee,
          } as any)
      )
      return new Promise<number>((res, rej) => {
        let counter = 60
        const allowanceCheckInterval = setInterval(() => {
          getAllowance(contract)
            .then((newAllowance) => {
              if (newAllowance !== permissionedAllowance) {
                res(newAllowance)
                clearInterval(allowanceCheckInterval)
              } else if (counter === 0) {
                rej('TIMEOUT_ALLOWANCE')
                clearInterval(allowanceCheckInterval)
              }
              counter--
            })
            .catch(console.log)
        }, 1000)
      })
    } else {
      return permissionedAllowance
    }
  }

  async function _estimateGasFee() {
    return estimateGasFeeFromEnv(chain!.gasFeeEndpoint)
  }

  async function setSignedApproval() {
    const nftAddress = nftContractDefinition!.smartContract!.address!
    const nonce = web3Utils!.randomHex(32)
    const deadline = Math.trunc(+new Date() / 1000 + SIGNATURE_TTL_SECONDS)
    const message = {
      owner: profile?.wallet,
      operator: tenant?.sales?.polygon!,
      approved: true,
      nonce,
      deadline,
    }
    const typedData = web3Utils.buildMessage(
      chain!.id,
      nftAddress,
      message,
      'Permit',
      'https://rarum.io'
    )
    const { v, r, s } = await web3Utils.signMessage(typedData, profile?.wallet)
    const { id } = await doForwardApproval({
      galleryId: gallery?.id,
      operator: tenant?.sales?.polygon!,
      approved: true,
      nonce,
      deadline,
      v,
      r,
      s,
    })
    return id
  }
  async function createSignedOffer(offerRequest: OfferForm) {
    const nftAddress = nftContractDefinition!.smartContract!.address!
    const currencySymbol = Object.keys(offerRequest.amount)[0]
    const { address: paymentTokenAddress, decimals } =
      currencyDetails[currencySymbol]
    const userAccount = profile!.wallet!

    const price = convertToInteger(
      (offerRequest.amount[currencySymbol]?.amount ?? '0').toString(),
      decimals.toString()
    )

    let days = 30

    if (offerRequest.selectedDeadline === PREDEFINED_DEADLINES.TWO_MONTHS)
      days = 60
    if (offerRequest.selectedDeadline === PREDEFINED_DEADLINES.THREE_MONTHS)
      days = 90

    const offer = {
      supply: offerRequest.selectedQuantity,
      maxUnitsPerPurchase: offerRequest.selectedQuantity,
      startTime: Math.trunc(+new Date() / 1000),
      endTime: Math.trunc(+Date.now() / 1000 + days * 24 * 60 * 60),
      owner: userAccount,
      metadataURI: 'https://',
      active: true,
    }

    const paymentOptions = {
      price,
      beneficiary: userAccount,
      paymentToken: paymentTokenAddress,
    }

    const deliveryOptions = {
      tokenId: offerRequest.tokenId!,
      lootBoxOptionId: 0,
      nft: nftAddress,
      lootBox: ZERO_ADDRESS,
      deliveryType: DELIVERY_TYPE.TRANSFER,
    }

    const nonce = web3Utils!.randomHex(32)
    const deadline = Math.trunc(+new Date() / 1000 + SIGNATURE_TTL_SECONDS)
    const message = {
      owner: userAccount,
      authorization: nonce,
      supply: offer.supply,
      nft: deliveryOptions.nft,
      tokenId: deliveryOptions.tokenId,
      price: paymentOptions.price,
      paymentToken: paymentOptions.paymentToken,
      beneficiary: paymentOptions.beneficiary,
      nonce,
      deadline,
    }

    const typedData = web3Utils.buildMessage(
      chain!.id,
      tenant?.sales?.polygon,
      message,
      'CreateOffer',
      'GenericTypedMessage'
    )

    const { v, r, s } = await web3Utils.signMessage(typedData, userAccount)

    const authorization = {
      authorization: nonce,
      deadline,
      signer: userAccount,
      v,
      r,
      s,
    }

    const { id } = await doCreateSignedOffer({
      offer,
      paymentOptions,
      deliveryOptions,
      authorization,
    })
    // @todo monitor transaction status
    return id
  }
  const _getNFTMovementAllowance = useCallback(
    async function () {
      return await isApprovedNftManagement(
        erc1155Contract!,
        profile!.wallet!,
        tenant!.sales!.polygon
      )
    },
    [erc1155Contract, profile, tenant]
  )

  async function _approveAllowance() {
    const tId = await setSignedApproval()
    return await new Promise<true>((r) => {
      const unsubscribe = observeOkenTransaction(tId, (t) => {
        if (t.status === 'MINED') {
          r(true)
          unsubscribe()
        }
      })
    })
  }

  async function _getTokensAllowance(token: string) {
    const contract = CurrencyContracts[token]
    const permissionedAllowance = await getAllowance(contract)
    return permissionedAllowance
  }

  async function _approveTokensAllowance(token: string, baseValue: number) {
    const contract = CurrencyContracts[token]
    const estimatedMaxGassFee = await _estimateGasFee()
    await dispatchAndWait(
      contract.methods
        .approve(
          tenant!.sales!.polygon,
          convertToInteger(
            String(Math.max(1000, baseValue)),
            String(
              STABLE_TOKENS.find(
                (a) => a.address === (contract as any).options.address
              )!.decimals
            )
          )
        )
        .send({
          from: profile!.wallet!,
          maxPriorityFeePerGas: estimatedMaxGassFee,
        })
    )
  }

  async function _createOffer(offer: OfferForm) {
    const offerTxId = await createSignedOffer(offer)
    const offerId = await new Promise<string>((r) => {
      const unsubscribe = observeOkenTransaction(offerTxId, (t) => {
        if (t.status === 'MINED') {
          r(t.okenTransaction.events[0].values.index)
          unsubscribe()
        }
      })
    })
    marketplaceContext.sales.getNextPage('')
    return offerId
  }

  interface PolygonGasFeeType {
    estimatedBaseFee: number
    standard: {
      maxPriorityFee: number
      maxFee: number
    }
  }

  return (
    <Context.Provider
      value={{
        approveTokenAllowance: _approveTokensAllowance,
        getTokensAllowance: _getTokensAllowance,
        updateBalance: updateBalance,
        createOffer: async (offer) => {
          const isApproved = await _getNFTMovementAllowance()
          if (!isApproved) {
            await _approveAllowance()
          }

          return await _createOffer(offer)
        },
        operationControl: {} as any,
        removeOffer: async (offerId: string) => {
          const estimatedMaxGassFee = await _estimateGasFee()
          const tHash = await dispatchAndWait(
            salesContract?.methods
              .setOfferStatus(profile?.wallet!, offerId, false)
              .send({
                from: profile!.wallet!,
                maxPriorityFeePerGas: estimatedMaxGassFee,
              } as any)
          )
          await waitForConfirmation(tHash)
          return offerId
        },
        updateOffer: async (offerId, offer) => {
          const estimatedMaxGassFee = await _estimateGasFee()
          if ('amount' in offer) {
            const currency = Object.keys(offer.amount)[0]
            await sendAndWaitForConfirmation(
              salesContract?.methods
                .updatePrice(
                  profile?.wallet!,
                  offerId,
                  normalizeUnit(
                    offer.amount[currency]!.amount,
                    STABLE_TOKENS.find((a) => a.symbol === currency)!.decimals
                  ).toFixed(0)
                )
                .send({
                  from: profile!.wallet!,
                  maxPriorityFeePerGas: estimatedMaxGassFee,
                } as any)
            )
          }
          if ('selectedQuantity' in offer)
            await sendAndWaitForConfirmation(
              salesContract?.methods
                .updateSupply(profile?.wallet!, offerId, offer.selectedQuantity)
                .send({
                  from: profile!.wallet!,
                  maxPriorityFeePerGas: estimatedMaxGassFee,
                } as any)
            )
          return offerId
        },
        buyOffer: (offerId) => {
          return new Promise((r, rej) => {
            let transactionHash!: string
            polledHook.process(async () => {
              try {
                const estimatedMaxGassFee = await _estimateGasFee()
                await checkAllowance(
                  offerId.currency,
                  offerId.price,
                  estimatedMaxGassFee
                )
                transactionHash = await dispatchAndWait(
                  salesContract?.methods
                    .buy(
                      offerId.wallet,
                      offerId.offerId,
                      web3Utils!.toWei(offerId.price.toString()),
                      '1'
                    )
                    .send({
                      from: profile!.wallet!,
                      maxPriorityFeePerGas: estimatedMaxGassFee,
                    } as any)
                )
                await waitForConfirmation(transactionHash)
                r()
              } catch (e) {
                rej()
              }
            })
          })
        },
        updateWallet: async (wallet) => {
          const message = {
            account: wallet,
            timestamp: Date.now().toString(),
          }
          const signature = await signMessageHook.signMessageFn({
            address: wallet,
            message: JSON.stringify(message, null, 2),
          })

          await doUpdateWallet(
            {
              ...message,
              message: 'Connect my account: ' + wallet,
            },
            signature
          )
        },
        balance: currencyBalanceMap,
        getNftMovementAllowance: _getNFTMovementAllowance,
        approveNFTManagement: _approveAllowance,
        createOfferOnly: _createOffer,
      }}>
      {children}
    </Context.Provider>
  )
}

export function useMarketplaceUser(): MarketplaceUserContextShape & {
  control: ReturnType<typeof useAsyncControl>
} {
  const {
    getNftMovementAllowance,
    approveNFTManagement,
    createOfferOnly,
    getTokensAllowance,
    approveTokenAllowance,
    buyOffer,
    ...context
  } = useContext(Context)
  const asyncControl = useAsyncControl({
    getNftMovementAllowance,
    approveNFTManagement,
    createOfferOnly,
    getTokensAllowance,
    approveTokenAllowance,
    buyOffer,
  })
  const updateWallet = useCallback<MarketplaceUserContextShape['updateWallet']>(
    (...args) => {
      return asyncControl.process(async () => {
        await context.updateWallet(...args)
      })
    },
    []
  )
  const createOffer = useCallback<MarketplaceUserContextShape['createOffer']>(
    (...args) => {
      return new Promise((r, rej) => {
        return asyncControl.process(async () => {
          return await context
            .createOffer(...args)
            .then(r)
            .catch((e) => {
              rej(e)
              return Promise.reject(e)
            })
        })
      })
    },
    []
  )

  return {
    ...context,
    control: asyncControl,

    updateWallet,
    createOffer,
    getNftMovementAllowance: asyncControl.getNftMovementAllowance,
    approveNFTManagement: asyncControl.approveNFTManagement,
    createOfferOnly: asyncControl.createOfferOnly,
    getTokensAllowance: asyncControl.getTokensAllowance,
    approveTokenAllowance: asyncControl.approveTokenAllowance,
    buyOffer: asyncControl.buyOffer,
  }
}
