const DEBUG_ON = process.env.DEBUG_LOG === 'true'
const DEBUG_OFF = process.env.DEBUG_LOG === 'false'

const log = function (...args: any) {
  if (
    (require('core/constants').__DEV__ && !DEBUG_OFF) ||
    DEBUG_ON ||
    (typeof window !== 'undefined'
      ? (window as any).debugKyrme === true
      : false)
  ) {
    console.log.apply(console, args)
  }
}

export default log
