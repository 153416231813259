import { ERC1155ABI } from 'core/constants'
import { ContractType } from 'core/logic/contract/types/types'
import IMyCollectionFacade, {
  BalancesOfType,
  FacadeTypes,
} from 'service/IMyCollectionFacade'
import Web3 from 'web3'

export default class ERC1155OnchainFacade extends IMyCollectionFacade {
  nftContractForRead!: Contract.ERC1155

  constructor(nftAddress: string, web3ForRead: Web3, web3ForWrite: Web3) {
    super(nftAddress, FacadeTypes.TOKENS, web3ForRead, web3ForWrite)
    this.nftContractForRead = new this.web3ForRead.eth.Contract(
      ERC1155ABI,
      this.contractAddress
    ) as any
  }
  async getOwnedItems(): Promise<BalancesOfType<ContractType.RarumNFT>[]> {
    const balances: BalancesOfType<ContractType.RarumNFT>[] = []
    for (let wallet of this.wallets) {
      const { balances: allBalances, tokens: allTokens } =
        await this.nftContractForRead.methods.balancesOf(wallet).call()
      const tokensWithBalance = allBalances
        .map((balance, index) => ({
          token: Number(allTokens[index]),
          balance: Number(balance),
        }))
        .filter((a) => a.balance > 0)

      for (let tokenWithBalance of tokensWithBalance) {
        const tokenWithBalanceFromAnotherWallet = balances.find(
          (b) => b.tokenId === tokenWithBalance.token
        )
        if (tokenWithBalanceFromAnotherWallet) {
          tokenWithBalanceFromAnotherWallet.balance += tokenWithBalance.balance
        } else {
          balances.push({
            type: ContractType.RarumNFT,
            balance: tokenWithBalance.balance,
            tokenId: tokenWithBalance.token,
          })
        }
      }
    }
    return balances
  }
}
