import { AlertType } from 'components/GlobalAlert/GlobalAlert.types'
import { AvailableHighlights } from 'components/Highlights/Highlights.types'
import { TenantCurrenciesType } from 'core/types/TenantCurrenciesType'
import { PaymentMethods } from 'pages/Authenticated/Payment/Payment.types'
import { mapActions } from './tenant.reducer'
import { ContractConfig } from '../contract/types/types'
import { Chains } from 'context/Chain'
import { EntityFeatureFlag } from 'context/Feature'
import { Prerequireable } from 'context/FlowPrerequirements'

export enum PaymentsProvider {
  COINBASE = 'coinbase',
  STRIPE = 'stripe',
  JUNO = 'juno',
  WALLET = 'wallet',
  MERCADO_PAGO = 'mercadoPago',
}

export type PaymentProviders = Record<
  PaymentsProvider,
  {
    active: boolean
    methods?: Record<
      PaymentMethods,
      boolean | ({ active: boolean } & Prerequireable)
    >
  }
>

export type TenantType = {
  id: string
  created: Date
  displayName: string
  chain: Chains
  lootbox: {
    address: string
    items: string[]
    packs: string[]
  }
  auction?: {
    polygon: string
  }
  stable: {
    address: string
  }
  languages: LanguageCodes[]
  sellsPack: boolean
  sellsSingle: boolean
  disableUserTransfers: boolean
  paymentProviders: PaymentProviders
  usesGalleries?: boolean | null
  currencies?: TenantCurrenciesType
  phoneValidation: boolean
  highlights?: AvailableHighlights
  alerts?: AlertType[]
  kycEnabled?: boolean
  sales?: {
    polygon: string
  }
  cvm?: {
    minInvestment: number
  }
  headlessGalleryId?: string
}

export type DeprecatedTenantType = TenantType &
  ContractConfig &
  EntityFeatureFlag

export type TenantState = {
  tenant: TenantType | null
  errorKey: string
}

export type TenantActions = typeof mapActions
export type TenantActionsTypes = keyof TenantActions
export type TenantAction = ReturnType<TenantActions[TenantActionsTypes]>
export type TenantPayload = Parameters<TenantActions[TenantActionsTypes]>
export type TenantActionReturn<T extends TenantActionsTypes> = ReturnType<
  TenantActions[T]
>
