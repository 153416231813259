import './setup'
import { LS_PENDING_CREDENTIAL } from 'core/constants'
import { logger } from 'core/helpers'
import { reducer as assetReducer } from 'core/logic/asset/asset.reducer'
import { reducer as galleryReducer } from 'core/logic/gallery/gallery.reducer'
import {
  actions as authActions,
  reducer as authReducer,
} from 'core/logic/authentication/auth.reducer'
import { reducer as blockReducer } from 'core/logic/block/block.reducer'
import { reducer as countersReducer } from 'core/logic/counters/counters.reducer'
import { reducer as deliveryReducer } from 'core/logic/delivery/delivery.reducer'
import { reducer as dropReducer } from 'core/logic/drop/drop.reducer'
import { reducer as mainReducer } from 'core/logic/main/main.reducer'
import { reducer as purchaseReducer } from 'core/logic/purchase/purchase.reducer'
import { reducer as tenantReducer } from 'core/logic/tenant/tenant.reducer'
import { reducer as tokenReducer } from 'core/logic/token/token.reducer'
import { reducer as userReducer } from 'core/logic/user/user.reducer'
import { reducer as termsReducer } from 'core/logic/terms/terms.reducer'
import { RarumUserProfile } from 'core/logic/user/user.types'
import { auth } from 'core/modules/firebase'
import allSagas from 'core/modules/saga'
import firebase from 'firebase/compat/app'
import { FirebaseReducer, firebaseReducer } from 'react-redux-firebase'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import createSagaMiddleware from 'redux-saga'

// Middlewares
const sagaMiddleware = createSagaMiddleware()
const allMiddlewares = [sagaMiddleware]

// AllReducers
const allReducers = Object.freeze({
  asset: assetReducer,
  authentication: authReducer,
  firebase: firebaseReducer as (
    state: any,
    action: any
  ) => FirebaseReducer.Reducer<RarumUserProfile>,
  firestore: firestoreReducer,
  main: mainReducer,
  user: userReducer,
  drop: dropReducer,
  counters: countersReducer,
  gallery: galleryReducer,
  delivery: deliveryReducer,
  purchase: purchaseReducer,
  token: tokenReducer,
  tenant: tenantReducer,
  block: blockReducer,
  terms: termsReducer,
})

// Enhancers
const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<ReturnType<typeof buildStore>['getState']>
// Inferred type reducer: reducerType
export type AppDispatch = ReturnType<typeof buildStore>['dispatch']

// For tests
export const buildStore = (
  reducers = allReducers,
  middlewares = allMiddlewares,
  initialState?: any,
  runSagas: boolean = false
) => {
  const store = createStore(
    combineReducers(reducers),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )
  if (runSagas) sagaMiddleware.run(allSagas)

  // Register the listener on firebase.auth state
  auth.onAuthStateChanged((user) => {
    logger(`[ RARUM ] User has changed ${user ? 'sign-in' : 'sign-out'}.`)
    if (!user) {
      // Logged off.
      store.dispatch(authActions.unauthenticated())
    } else {
      // Logged in.

      // If a credential is pending, link it with the current logged-in user
      const pendingCredential = localStorage.getItem(LS_PENDING_CREDENTIAL)
      if (pendingCredential) {
        const credential =
          firebase.auth.AuthCredential.fromJSON(pendingCredential)
        localStorage.removeItem(LS_PENDING_CREDENTIAL)
        if (credential) {
          user.linkWithCredential(credential)
        }
      }

      store.dispatch(authActions.authenticated())
    }
  })

  return store
}

export default buildStore(undefined, undefined, undefined, true)
