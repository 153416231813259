import React, { useCallback } from 'react'
import View from './Home.view'
import { HomeProps, HomeViewProps } from './Home.types'
import { useHistory } from 'react-router-dom'
import ROUTES from 'core/modules/router'
import { useAllGalleryDrops, useAllHighlightDrops } from 'context/Gallery'
import useElementFit from '@onepercentio/one-ui/dist/hooks/useElementFit'

export default function HomeLogic(props: HomeProps) {
  const history = useHistory()
  const onSeeAll = useCallback<HomeViewProps['onSeeAll']>((type) => {
    switch (type) {
      case 'gallery':
        history.push(ROUTES.unauthenticated.allGalleries)
        break
      case 'drops':
        history.push(ROUTES.unauthenticated.marketplace)
        break
    }
  }, [])
  const { ref, itemsToShow } = useElementFit(302 + 26)
  const galleriesDrops = useAllGalleryDrops(itemsToShow || 0)
  const highlightedDrops = useAllHighlightDrops(itemsToShow || 0)

  return (
    <View
      onSeeAll={onSeeAll}
      dropsPaginable={galleriesDrops}
      dropsContainerRef={ref}
      highlightsPaginable={highlightedDrops}
    />
  )
}
