import { messages as rarumMessages } from './rarum.pt'
import merge from 'lodash/merge'
import openspaceMessages from 'openspace/translate/languages/pt'

const akomaMessages = {
  'pt-BR': {
    translations: {
      home: {
        title: 'Akoma Black Web3',
        description: 'Aquilombamento digital na Web3 com a Rarum',
        sections: {
          highlights: {
            title: 'Destaques',
          },
          gallery: {
            title: 'Conheça os projetos',
            action: {
              seeAll: 'Veja todos os projetos',
            },
          },
          akoma: {
            title: 'Akoma Ntoaso',
            about:
              'Akoma Ntoaso é um símbolo da Filosofia Adinkra que significa a conexão entre os corações, a concordância. Algo que dialoga de maneira positiva com a construção dos  modelos de consenso na Web3, em tecnologias Blockchain.',
            details:
              'O projeto é uma iniciativa da Rarum para a comunidade negra brasileira, africana e afrodiaspórica como espaço de construção de tecnologias de sobrevivência e potencialização das conexões ancestrais pelo uso da tokenização e demais tecnologias blockchain.',
            cta: 'Faça parte',
          },
        },
      },
    },
  },
} as const

const messages = merge(rarumMessages, openspaceMessages, akomaMessages)

export type OverwrittenAkomaMessages = (typeof akomaMessages)['pt-BR']
export { messages }
