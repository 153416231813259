// Code based on: https://dev.juno.com.br/api/v2#tag/Obtendo-o-hash

import { JUNO_PUBLIC_TOKEN, PRODUCTION } from 'core/constants'

declare const DirectCheckout: any

const sanitizeExpiration = (mmyy?: string) => {
  let expirationMonth
  let expirationYear
  const sanitized = mmyy ? mmyy.replace(/[/_]/g, '') : ''
  if (sanitized.length === 4) {
    expirationMonth = sanitized.substring(0, 2)
    // Update this when we reach year 2100 :)
    expirationYear = `20${sanitized.substring(2, 4)}`
  }
  return { expirationMonth, expirationYear }
}
interface ICardData {
  cardNumber: string
  holderName: string
  securityCode: string
  expiration: string
}
/**
 * Example of getCardHash:
 * { cardNumber: '0000000000000000',
 * holderName: 'Nome do Titular do Cartão',
 * securityCode: '000',
 * expiration: '12/2045' }
 * @param param0 Card data
 * @returns Card hash
 */
export class CheckoutInstance {
  checkoutInstance: any

  getCheckoutInstance() {
    if (!this.checkoutInstance) {
      this.checkoutInstance = new DirectCheckout(JUNO_PUBLIC_TOKEN, PRODUCTION)
    }
    return this.checkoutInstance
  }
  async getCardHash({ cardNumber, holderName, securityCode, expiration }: ICardData) {
    return new Promise<string>((res, err) => {
      const { expirationMonth, expirationYear } = sanitizeExpiration(expiration)
      this.getCheckoutInstance().getCardHash(
        { cardNumber: cardNumber.replaceAll(' ', ''), holderName, securityCode, expirationMonth, expirationYear },
        function (cardHash: string) {
          /* Sucesso - A variável cardHash conterá o hash do cartão de crédito */
          res(cardHash)
        },
        function (error: string) {
          /* Erro - A variável error conterá o erro ocorrido ao obter o hash */
          err(error)
        }
      )
    })
  }
  isValidCardNumber(cardNumber: string) {
    return this.getCheckoutInstance().isValidCardNumber(cardNumber)
  }
  isValidSecurityCode(cardNumber: string, securityCode: string) {
    return this.getCheckoutInstance().isValidSecurityCode(cardNumber, securityCode)
  }
  isValidExpireDate(mmYY: string) {
    const { expirationMonth, expirationYear } = sanitizeExpiration(mmYY)
    if (Number(expirationMonth) > 12) return false
    return this.getCheckoutInstance().isValidExpireDate(expirationMonth, expirationYear)
  }
  isValidCardData(cardData: ICardData) {
    return this.getCheckoutInstance().isValidCardData(cardData)
  }
  getCardType(cardNumber: string) {
    return this.getCheckoutInstance().getCardType(cardNumber)
  }
}
