import { PaletteType } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { OneUI, overrides } from '../base'

// colors

const paletteType: PaletteType = 'light'

// greys
const white = '#000'
const grey = {
  100: '#F4F5F7',
  200: '#E4E5E9',
  300: '#BEC1CA',
  400: '#6D6E71',
  500: '#444546',
  600: '#282B35',
  700: '#0D0D0D',
  800: '#181818',
}

// rarum

// client
const client = {
  secondary: {
    a: '#CFEFA0',
    b: '#64fdcf',
    c: '#6EA71D',
  },
}

// support
const support = {
  success: {
    a: '#fff',
    b: '#fff',
  },
  error: {
    a: '#FF7B7B',
    b: '#CC170D',
    c: '#890A03',
  },
}

// pages' background
const background = '#fff'

// border
// const borderWidth = 2
// export const borderColor = grey[300]

// breakpoints
const xl = 1920
const lg = 1440
const md = 768
const sm = 414
const xs = 0

//mixins
const toolBarHeight = 72

export default function lightThemeFactory(
  lightColor: string,
  mainColor: string = lightColor,
  darkColor: string = lightColor,
  fonts: {
    primary: string
    secondary: string
  }
) {
  const paletteOverride = {
    dropOpen: {
      bg: `linear-gradient(0deg, #fff 0%, #0000 100%), var(--digital-blue)`,
    },
    promo: {
      main: '#FE4A74',
      secondary: '#BA14C1',
    },
    pink: {
      400: '#F807C0',
      500: '#E33960',
    },
    purple: {
      400: '#A045E8',
      500: '#7940E3',
    },
    grey: {
      200: '#C4C4C4',
    },
    nero: '#181818',
    sky: '#6A767D',
    type: paletteType,
    primary: {
      light: lightColor,
      main: mainColor,
      dark: darkColor,
      contrastText: '#fff',
    },
    info: {
      light: client.secondary.a,
      main: client.secondary.b,
      dark: client.secondary.c,
      contrastText: darkColor,
    },
    text: {
      secondary: '#626262',
      primary: '#626262',
    },
    success: { light: support.success.a, main: support.success.b },
    divider: '#fff4',
    common: {
      black: grey[700],
      white,
    },
    background: {
      default: background,
      paper: '#F5F9FA',
    },
    alert: {
      background: '#F2F5F9',
    },
    tonalOffset: 0,
    rarum: {
      banner: {
        bg: 'var(--secondary-background)',
      },
      claim: {
        bg: 'linear-gradient(180deg, #F9F9F9 0%, #808080 76.48%)',
      },
      footer: {
        overflowSpacing: 0,
        bgColor: '#282B35',
      },
    },
    oneui: {
      status: {
        success: '#78ADA0',
      },
      button: {
        background: '#fff',
        textColor: '#000',
        inverseTextColor: '#fff',
        primary: {
          textColor: '#fff',
        },
        outline: {
          default: '#626262',
          light: {
            text: '#626262',
            background: '#D5D6D5',
          },
          hover: {
            textColor: '#aaa',
          },
        },
        highlight: {
          primary: '#00E1FF',
          secondary: '#DE00E9',
          text: '#fff',
        },
        filled: {
          hover: {
            background: '#ddd',
            textColor: '#000',
          },
        },
      },
    },
  } as const

  const theme = createTheme({
    typography: {
      font: {
        primary: fonts.primary,
        secondary: fonts.secondary,
      },
      fontFamily: 'var(--main-font)',
      fontSize: 14,
      h1: {
        fontSize: '2.4rem',
        fontWeight: 800,
        [`@media (min-width:${md}px)`]: {
          fontSize: '2.8rem',
        },
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 200,
      },
      h3: {
        fontSize: '1.3rem',
      },
      h4: {
        fontSize: '1rem',
        fontWeight: 700,
      },
      h5: {
        fontSize: '1.4rem',
      },
      h6: {
        fontSize: '1.2rem',
      },
      subtitle1: {
        fontSize: '1.7rem',
      },
      subtitle2: {
        fontSize: '1.25rem',
        fontWeight: 700,
      },
      body1: {
        fontSize: '1rem',
      },
      body2: {
        fontSize: '0.875rem',
        [`@media (min-width:${md}px)`]: {
          fontSize: '0.9rem',
        },
      },
    },
    mixins: { toolbar: { minHeight: toolBarHeight } },
    shape: { borderRadius: 4 },
    palette: paletteOverride,
    breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xl,
        lg,
        md,
        sm,
        xs,
      },
    },
  })

  theme.overrides = overrides(theme) as any
  theme.palette.oneui = OneUI() as any

  return theme
}
