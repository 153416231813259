import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { Controller, FieldValues } from 'react-hook-form'
import MaskedTextInput from '../MaskedTextInput'
import useStyle from './FormField.style'
import {
  CommonField,
  ControlledField,
  ErrorsField,
  InnerElement,
  MaskedField,
  SelectField,
} from './FormField.types'

type MaskedProps<T extends FieldValues> = CommonField<T> &
  Partial<SelectField> &
  ControlledField<T> &
  Partial<MaskedField> &
  ErrorsField<T>

const FormField = <T extends FieldValues>({
  autoComplete,
  control,
  disabled,
  errors,
  fieldPath,
  hideMaskPlaceholder,
  inputMode,
  label,
  mask,
  options,
  showEmptyOption = true,
  required,
  type,
  min,
  max,
  className,
  multiline,
  margin = 2,
}: PropsWithChildren<
  MaskedProps<any> & {
    margin?: number
  }
>) => {
  const classes = useStyle({
    margin,
  })

  let InnerElement: InnerElement<T>

  const errorsDefined = errors !== undefined
  const error = errors ? errors[fieldPath as unknown as keyof T] : false
  const errMessage = error ? error.message : errorsDefined ? ' ' : undefined

  if (options) {
    InnerElement = ({ field: { onChange, onBlur, value } }) => {
      return (
        <>
          <FormControl
            className={clsx(classes.field, className)}
            size='small'
            variant='outlined'
            error={error !== undefined}>
            <InputLabel htmlFor={`${fieldPath}-select`}>{label}</InputLabel>
            <Select
              id={`${fieldPath}-select`}
              native
              label={label}
              autoComplete={autoComplete}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={disabled}>
              {showEmptyOption && <option aria-label='None' value='' />}
              {options.map(({ key, value, label }) => (
                <option key={key} value={value}>
                  {label}
                </option>
              ))}
            </Select>
            {errorsDefined && (
              <FormHelperText error>{errMessage}</FormHelperText>
            )}
          </FormControl>
        </>
      )
    }
  } else if (mask) {
    InnerElement = ({ field: { onChange, onBlur, value } }) => (
      <MaskedTextInput
        label={label}
        mask={mask}
        {...(inputMode ? { inputProps: { inputMode, min, max, type } } : {})}
        alwaysShowMask={false}
        maskChar={hideMaskPlaceholder ? null : undefined}
        onChange={onChange}
        onBlur={onBlur}
        value={value as any}
        disabled={disabled}
        error={error !== undefined}
        helperText={errorsDefined ? errMessage : undefined}
        className={clsx(classes.field, className)}
      />
    )
  } else {
    InnerElement = ({ field: { onChange, onBlur, value } }) => (
      <TextField
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        label={label}
        type={type}
        multiline={multiline}
        {...(inputMode ? { inputProps: { inputMode, min, max, type } } : {})}
        autoComplete={autoComplete}
        error={error !== undefined}
        helperText={errorsDefined ? errMessage : undefined}
        className={clsx(classes.field, className)}
      />
    )
  }

  return (
    <Controller
      control={control}
      name={fieldPath as any}
      rules={{ required }}
      render={InnerElement as any}
    />
  )
}

export default FormField
