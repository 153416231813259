import { types as AppTypes } from 'core/app'
import { builder, logger } from 'core/helpers'

export enum LS_KEYS {
  REDIRECT = 'redirect',
}
const ls = localStorage

const adapter = (key: LS_KEYS) => {
  return {
    getString: (): AppTypes.ServiceResult<string> => {
      try {
        return builder.adapterOK(ls.getItem(key) || '')
      } catch (e) {
        logger(e)
        return builder.adapterError('localStorage.setString')
      }
    },
    setString: (value: string): AppTypes.ServiceResult => {
      try {
        ls.setItem(key, value)
      } catch (e) {
        logger(e)
        return builder.adapterError('localStorage.setString')
      }
      return builder.adapterOK()
    },
    remove: (): AppTypes.ServiceResult => {
      try {
        ls.removeItem(key)
      } catch (e) {
        logger(e)
        return builder.adapterError('localStorage.removeItem')
      }
      return builder.adapterOK()
    },
  }
}

export default adapter
