import { lazy, RefObject, Suspense } from 'react'
import Container from 'openspace/components/Container'
import { HomeViewProps } from './Home.types'
import Styles from './Home.module.scss'
import Text from 'openspace/components/Text/Text'
import { t, tE, tO } from 'translate/i18n'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { useGalleries } from 'core/logic/gallery/gallery.hook'
import Section from 'openspace/components/Section'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import LazyBadge from 'openspace/components/LazyBadge'
import Footer from 'components/Footer'
import { HomeTestIds } from './Home.e2e'
import GalleryCard from 'openspace/components/GalleryCard'
import { OfferType } from 'core/logic/drop/drop.types'
import { Paginable } from '@onepercentio/one-ui/dist/hooks/usePagination'
import DropItem from 'openspace/components/DropItem'
import { DropItemViewMode } from 'openspace/components/DropItem/DropItem.types'
import { useClientConfig } from 'context/ClientContext'
import { IPOBanner } from 'pages/Unauthenticated/Drops/Details/Models/IPO/IPO.logic'
import { Card, CardContent, CardMedia } from '@material-ui/core'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import AkomaCard from 'assets/images/akoma/akoma_card.png'

const LogoType = lazy(() => import('../../../components/Background/BG'))

function GalleriesSection({ onSeeAll }: { onSeeAll: () => void }) {
  const { galleries } = useGalleries()
  return (
    <>
      <Section
        data-testid={HomeTestIds.GallerySection}
        title={tE('home.sections.gallery.title')}
        seeAll={{
          onClick: onSeeAll,
          label: tO('home.sections.gallery.action.seeAll'),
        }}
        src={galleries || []}
        jsx={{
          empty: null,
          loading: null,
          baseWidth: 302 + 26,
          factory: (b) => (
            <GalleryCard
              gallery={b}
              shouldExpandOnMount={false}
              mode={b.highlight ? 'highlight' : 'default'}
            />
          ),
        }}
      />
    </>
  )
}

function LatestItensSection({
  drops,
  containerRef,
}: {
  drops: Paginable<OfferType[]>
  containerRef: RefObject<HTMLDivElement>
}) {
  return (
    <Section
      ref={containerRef}
      data-testid={HomeTestIds.ItemsSection}
      title={tO('home.sections.latest.title')}
      src={drops}
      jsx={{
        empty: null,
        loading: null,
        baseWidth: 302 + 26,
        factory: (asset) => (
          <DropItem
            mode={DropItemViewMode.EXPANDED}
            drop={asset}
            key={asset.id}
            availability={'open'}
          />
        ),
      }}
    />
  )
}

function Partnership() {
  return (
    <Container className={Styles.partnership}>
      <div>
        <Suspense fallback={<svg />}>
          <LogoType />
        </Suspense>
        <LazyBadge />
      </div>
      <div>
        <Text type={FigmaTypo.H2} code='home.sections.partnership.title'/>
        <Text type={FigmaTypo.P16} code='home.sections.partnership.description' />
        {/* <Button variant='outline'>
          {tO('home.sections.partnership.actions.knowMore')}
        </Button> */}
      </div>
    </Container>
  )
}

function HighlightsSection({ drops }: { drops: Paginable<OfferType[]> }) {
  return (
    <Section
      data-testid={HomeTestIds.ItemsSection}
      title={tO('home.sections.highlights.title')}
      src={drops}
      jsx={{
        empty: null,
        loading: null,
        baseWidth: 302 + 26,
        factory: (asset) => {
          switch (asset.type) {
            case 'cvm88':
              return <IPOBanner offer={asset} />
            default:
              throw new Error(
                `There is no highlight banner defined for offers of type ${asset.type}`
              )
          }
        },
      }}
    />
  )
}

/**
 * Where the user checks out the galeries
 **/
export default function HomeView(props: HomeViewProps) {
  const {
    home: { banner },
  } = useClientConfig()

  return (
    <>
      <div
        className={`${Styles.homeBanner} light`}
        style={{ backgroundImage: `url(${banner})` }}>
        <Container>
          <Text type={FigmaTypo.H2} code='home.title' />
          <Text type={FigmaTypo.H4} code='home.description' />
        </Container>
      </div>
      <div className={Styles.hero}>
        <Container>
          <Card style={{ display: 'flex' }}>
          <CardMedia
            component="img"
            image={AkomaCard}
            alt="Adinkras"
            style={{ width: '30%', objectFit: 'cover' }}
          />
            <CardContent>
            <Text
              type={FigmaTypo.H2}
              code='home.sections.akoma.title'
            />
            <Spacing size='large' />
            <Text
              type={FigmaTypo.P16}
              code='home.sections.akoma.about'
            />
            <Text
              type={FigmaTypo.P16}
              code='home.sections.akoma.details'
            />
            <Spacing size='large' />
            <Button variant='filled' onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdLEGB9_SUDwTJSqhL3Fw3cNMr-ALNBWp_orzoBe0EeU894PQ/viewform?usp=sf_link", "_blank")}>
              {t('home.sections.akoma.cta')}
            </Button>
            </CardContent>
          </Card>
        </Container>
      </div>
      <Spacing size='large' />

      <GalleriesSection key={'g'} onSeeAll={() => props.onSeeAll('gallery')} />

      <Partnership />
      <Footer />
    </>
  )
}
