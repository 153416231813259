import { ClientContextShape } from 'context/ClientContext'
import { ReactComponent as Logo } from 'assets/images/akoma/akoma_logo.svg'

export default {
  home: {
    // banner: 'https://firebasestorage.googleapis.com/v0/b/ents-35a9e.appspot.com/o/tenant%2Fakoma%2Fspacial-colors-and-glow-and-stars-on-the-left-88819407.png?alt=media&token=91e71458-f452-4ecb-b21f-6329b8d8aec5',
    banner: 'https://firebasestorage.googleapis.com/v0/b/ents-35a9e.appspot.com/o/tenant%2Fakoma%2Fafrofuturistic-brave-woman-looking-beyond-with-spacial-background-with-c0957e-color-739622814.png?alt=media&token=dd3df480-df8e-4701-b48b-b3efe3813135',
  },
  Logotype: Logo,
  typography: {
    font: 'Inter',
    headFont: 'Inter',
  },
} as ClientContextShape
