import { ComponentProps, lazy, Suspense } from 'react'

const Lazy = lazy(() => import('./LazyBadge'))

export default function LazyBadge(
  props: ComponentProps<typeof import('./LazyBadge')['default']>
) {
  return (
    <Suspense fallback={null}>
      <Lazy {...props} />
    </Suspense>
  )
}
