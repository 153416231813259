import { FieldPath } from '@onepercentio/one-ui/dist/type-utils'
import get from 'lodash/get'
import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { Get } from 'type-fest'

export enum HomeHeadingDisplayModel {
  /** This displays the default banner for the tenant */
  BANNER,
  /** This displays the promotional banner */
  PROMO,
}

export type DisplayModelsContextShape = {
  home: {
    heading: HomeHeadingDisplayModel
  }
}

export const DisplayModelContext = createContext<DisplayModelsContextShape>(
  null as any
)

/**
 * The purpose of this context, is to provide a way to easily toggle display models of UI elements
 */
export default function DisplayModelsProvider({
  children,
}: PropsWithChildren<{}>) {
  const [displayModel, _setDisplayModel] = useState<DisplayModelsContextShape>(
    () => ({
      home: {
        heading: HomeHeadingDisplayModel.BANNER,
      },
    })
  )

  return (
    <DisplayModelContext.Provider value={displayModel}>
      {children}
    </DisplayModelContext.Provider>
  )
}

export function useDisplayModel<P extends FieldPath<DisplayModelsContextShape>>(
  model: P
): Get<DisplayModelsContextShape, P> {
  const ctx = useContext(DisplayModelContext)
  return get(ctx, model)
}