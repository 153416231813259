import { i18n, t } from 'translate/i18n'
import { Box, Container, Dialog, Grid, Typography } from '@material-ui/core'
import Footer from 'components/Footer'
import React, {
  useRef,
  ComponentProps,
  useLayoutEffect,
  useEffect,
  Fragment,
  useMemo,
} from 'react'
import AssetItem from './AssetItem'
import { AssetViewProps } from './AssetList.types'
import Freeze from '@onepercentio/one-ui/dist/components/Freeze'
import useStyles from './AssetList.style'
import { AssetListTestId } from './AssetList.e2e'
import Loading from 'components/Loading'
import AssetFilters from 'components/AssetFilters'
import MissingImage from 'assets/images/vw/pages/Unauthenticated/AssetList/empty-filter.svg'
import {
  useContainerPagination,
  useLocalPagination,
} from '@onepercentio/one-ui/dist/hooks/usePagination'
import useElementFit from '@onepercentio/one-ui/dist/hooks/useElementFit'
import Button from '@onepercentio/one-ui/dist/components/Button'
import OrderableList from '@onepercentio/one-ui/dist/components/OrderableList/OrderableList'
import { AssetType } from 'core/logic/asset/asset.types'
import { MarkeplaceTypeProps } from './AssetList.types'
import { OrderableListReorderMode } from '@onepercentio/one-ui/dist/components/OrderableList/OrderableList'

function ItemsList({
  items,
  allAssets,
  hasMarketplace,
  marketplace,
  filter,
}: {
  items: AssetType[]
  allAssets: AssetType[]
  hasMarketplace: boolean
  marketplace?: MarkeplaceTypeProps['marketplace']
  filter?: MarkeplaceTypeProps['filter']
}) {
  const classes = useStyles()
  const itemsToShow = items.map((asset, index) => {
    let marketplaceResume: ComponentProps<
      typeof AssetItem
    >['marketplaceResume'] = undefined
    if (hasMarketplace) {
      const salesResumeForAsset =
        marketplace!.sales && marketplace!.sales[asset.id]
      if (salesResumeForAsset) {
        const currency = filter!.currency || ''
        const priceInformationByCurrency = salesResumeForAsset.price[currency]
        if (priceInformationByCurrency) {
          marketplaceResume = {
            ...salesResumeForAsset,
            price: {
              currency,
              lowest: priceInformationByCurrency!.lowest,
            },
          }
        }
      }
    }
    return (
      <AssetItem
        asset={asset}
        key={`${asset.id}-${marketplaceResume ? 'marketplace' : 'card'}`}
        last={allAssets!.length - 1 === index}
        marketplaceResume={marketplaceResume}
      />
    )
  })
  const possibleOrder = useMemo(() => {
    const marketOrder: string[] = []
    const assetOrder: string[] = []
    for (let asset of allAssets) {
      marketOrder.push(`${asset.id}-marketplace`)
      assetOrder.push(`${asset.id}-card`)
    }

    return [...marketOrder, ...assetOrder]
  }, [allAssets])

  return (
    <OrderableList
      currentOrder={possibleOrder}
      className={classes.orderable}
      mode={OrderableListReorderMode.TWO_DIMENSIONS}>
      {itemsToShow}
    </OrderableList>
  )
}

const AssetView: React.FC<AssetViewProps> = ({
  assets,
  allAvailableAssets,
  onRarityFilter,
  onTraitFilter,
  onNoFilter,
  loading,
  ...props
}) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const intersectRef = useRef(null)
  const hasMarketplace = 'marketplace' in props && !!props.marketplace
  const mProps = props as MarkeplaceTypeProps
  const { itemsToShow, ref } = useElementFit(300, 400)
  const { items, getNextPage, getPage, src } = useLocalPagination(
    assets || [],
    (itemsToShow || 0) * 2
  )
  const { scrollableRef, customOptionsRef } =
    useContainerPagination(getNextPage)

  useLayoutEffect(() => {
    const roots = document.querySelectorAll('.scroll-root')
    if (roots) {
      ;(scrollableRef as any).current =
        roots.item(roots.length - 1)?.parentElement?.parentElement ||
        window.document
    }
    ;(ref as any).current = rootRef.current
  }, [scrollableRef, ref])

  useEffect(() => {
    if (!!itemsToShow && assets !== undefined) getPage(0)
  }, [assets, !!itemsToShow])

  useLayoutEffect(() => {
    customOptionsRef.current = () => ({
      offsetBottom:
        160 + rootRef.current!.parentElement!.nextElementSibling!.clientHeight,
      offsetLeft: 0,
    })
  }, [])

  return (
    <Fragment>
      <main className={classes.main}>
        <Container ref={rootRef} maxWidth='lg' className={classes.container}>
          <Grid container>
            <Grid container item className={classes.itemContainer}>
              {!hasMarketplace && (
                <>
                  <Typography
                    variant='h1'
                    component='h2'
                    color='textPrimary'
                    className={classes.title}>
                    {i18n.t('unauthenticated.galleryCards.title')}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='textSecondary'
                    className={classes.description}>
                    {i18n.t('unauthenticated.galleryCards.text1')}
                  </Typography>
                </>
              )}
              <AssetFilters
                allAvailableAssets={allAvailableAssets || []}
                counters={{
                  all: 0,
                  sale: 0,
                }}
                origin='marketplace'
                onRarityFilter={onRarityFilter}
                onTraitFilter={onTraitFilter}
                onNoFilter={onNoFilter}
                {...(hasMarketplace
                  ? {
                      ...props,
                      marketplace: true,
                      currencies: hasMarketplace
                        ? props.marketplace.currencies
                        : [],
                    }
                  : { filter: props.filter, marketplace: false })}
              />
              <div
                data-testid={AssetListTestId.ASSETS_LIST}
                className={classes.boxList}
                ref={intersectRef}>
                {hasMarketplace && props.marketplace.loading && <Loading />}
                <Dialog
                  classes={{
                    paperScrollPaper: classes.errorModal,
                  }}
                  open={hasMarketplace && !!props.marketplace.error}>
                  <Typography variant='body1'>
                    <Freeze>{hasMarketplace && props.marketplace.error}</Freeze>
                  </Typography>
                  <Button
                    variant='filled'
                    onClick={
                      hasMarketplace ? props.marketplace.onRetry : undefined
                    }>
                    {t('generic.retryBtn')}
                  </Button>
                </Dialog>
                {items?.length ? (
                  <ItemsList
                    allAssets={src!}
                    hasMarketplace={hasMarketplace}
                    items={items}
                    filter={mProps.filter}
                    marketplace={mProps.marketplace}
                  />
                ) : loading ? (
                  <>
                    <AssetItem data-testid={AssetListTestId.LOADER_ID} />
                    <AssetItem data-testid={AssetListTestId.LOADER_ID} last />
                  </>
                ) : (
                  <Box
                    className={classes.noResults}
                    data-testid={AssetListTestId.NO_RESULTS_FOR_FILTER}>
                    <img
                      src={MissingImage}
                      alt={t('unauthenticated.galleryCards.filterNoResult')}
                    />
                    <Typography variant='h2'>
                      {t('unauthenticated.galleryCards.filterNoResult')}
                    </Typography>
                    <Button variant='filled' onClick={onNoFilter}>
                      {t('unauthenticated.galleryCards.actions.clearFilters')}
                    </Button>
                  </Box>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
    </Fragment>
  )
}

export default AssetView
