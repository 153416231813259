import MarketplaceView from './Marketplace.view'
import AssetList from 'pages/Unauthenticated/Asset'
import { useEffect } from 'react'
import { useMarketplace } from 'context/Marketplace'
import { useFeatures } from 'context/Feature'

export default function MarketplaceLogic() {
  const { sales } = useMarketplace()
  const isMarketplaceEnabled = useFeatures().marketplace
  useEffect(() => {
    sales.getNextPage(undefined)
  }, [])
  return (
    <>
      <MarketplaceView />
      <AssetList marketplace={isMarketplaceEnabled} />
    </>
  )
}
