import React, { useEffect } from 'react'
import { ChatRoomViewProps } from './ChatRoom.types'
import Portal from 'components/Portal/Portal'
import AnimatedEntrance from '@onepercentio/one-ui/dist/components/AnimatedEntrance/AnimatedEntrance'
import ChatThreadLogic from './ChatThread/ChatThread.logic'
import SectionLoader from 'openspace/components/SectionLoader/SectionLoader'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import { ReactComponent as Zero } from 'assets/icons/zero.svg'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import { tO } from 'translate/i18n'
import Styles from './ChatRoom.module.scss'
import ChatThreadCreatorLogic from './ChatThreadCreator/ChatThreadCreator.logic'
import { useLocalPagination } from '@onepercentio/one-ui/dist/hooks/usePagination'
import useElementFit from '@onepercentio/one-ui/dist/hooks/useElementFit'
import { useContainerPagination } from '@onepercentio/one-ui/dist/hooks/usePagination'
import useMergeRefs from '@onepercentio/one-ui/dist/hooks/useMergeRefs'

export enum ChatRoomPortal {
  INPUT = 'chat_room_thread_portal',
}

/**
 * Manages a series of chat threads
 **/
export default function ChatRoomView({
  threads: staticThreads,
  roomId
}: ChatRoomViewProps) {
  const { ref, itemsToShow = 1 } = useElementFit(300, 220)
  const threads = useLocalPagination(staticThreads, itemsToShow * 2)
  const { scrollableRef } = useContainerPagination(threads.getNextPage, 'v')
  const mainRef = useMergeRefs(ref, scrollableRef)

  useEffect(() => {
    if (staticThreads !== undefined) threads.getPage(0)
  }, [staticThreads])

  return (
    <>
      <div ref={mainRef}>
        <Portal to={ChatRoomPortal.INPUT}>
          <ChatThreadCreatorLogic threadId={roomId} />
        </Portal>
        {threads.items === undefined ? (
          <SectionLoader />
        ) : !threads.items.length ? (
          <div className={Styles.noitems}>
            <Zero />
            <OneText type={FigmaTypo.P16}>{tO('forum.noItems')}</OneText>
          </div>
        ) : null}
        {threads.items !== undefined && (
          <AnimatedEntrance>
            {threads.items.map((thread) => (
              <ChatThreadLogic
                key={thread.createdAt.toISOString()}
                {...thread}
              />
            ))}
          </AnimatedEntrance>
        )}
      </div>
    </>
  )
}
