import { mapDropBidsData, mapDropData } from 'core/helpers/drop'
import { service } from 'core/modules/firebase'
import { DropBidListType, DropBidType, DropType } from './drop.types'

export const fetch = ({
  emitter,
}: {
  emitter: (input: DropType[]) => void
}) => {
  return service.fetchDrops({
    onSnapshot: (snap) => {
      const dropsUpdated = snap.docs.map((c) => {
        const data = c.data() as DropType
        const id = c.id
        return mapDropData(data, id)
      })
      emitter(dropsUpdated)
    },
  })
}

export const fetchDrop = ({
  dropId,
  emitter,
}: {
  dropId: string
  /**
   *
   * @param input null when it doesn't exists
   * @returns
   */
  emitter: (input: { drop: DropType | null }) => void
}) => {
  return service.fetchDrop({
    dropId,
    onSnapshot: (snap) => {
      const data = snap.data() as DropType
      const id = snap.id
      if (data) {
        const dropUpdated = mapDropData(data, id)
        emitter({
          drop: dropUpdated,
        })
      } else {
        emitter({
          drop: null,
        })
      }
    },
  })
}

export const fetchBidsDrop = ({
  dropId,
  emitter,
}: {
  dropId: string
  emitter: (input: DropBidListType) => void
}) => {
  return service.fetchDropBids({
    dropId,
    onSnapshot: (snap) => {
      const data = snap.docs.map((doc) =>
        mapDropBidsData(doc.data() as DropBidType)
      )
      emitter(data)
    },
  })
}
