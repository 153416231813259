import React from 'react'
import View from './DropItem.view'
import { DropItemProps } from './DropItem.types'
import { useHistory } from 'react-router-dom'
import {
  TO_ASSET_DETAIL,
  TO_DROP_DETAILS,
  TO_GALLERY,
  TO_MARKETPLACE_ASSET_DETAILS,
} from 'core/modules/router'
import { useDropRemainingCount } from 'core/logic/drop/drop.hook'
import { OfferType } from 'core/logic/drop/drop.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { BaseAssetType } from 'core/logic/asset/asset.types'

export { BaseExpandedWidth, BaseAssetWidth } from './DropItem.view'

function DropViewWithCounter(props: DropItemProps) {
  const history = useHistory()
  const { tenant } = useTenant()
  const remaining = useDropRemainingCount(props.drop as OfferType)
  return (
    <View
      {...props}
      tenant={tenant!}
      counters={{
        remaining: remaining,
      }}
      onClick={() => {
        history.push(TO_DROP_DETAILS(props.drop.id))
      }}
    />
  )
}

function DropView(props: DropItemProps) {
  const history = useHistory()
  const { tenant } = useTenant()
  return (
    <View
      {...props}
      tenant={tenant!}
      onClick={() => {
        history.push(TO_DROP_DETAILS(props.drop.id))
      }}
    />
  )
}

function AssetView(props: DropItemProps) {
  const history = useHistory()
  const { tenant } = useTenant()
  return (
    <View
      {...props}
      tenant={tenant!}
      onClick={() => {
        history.push(TO_ASSET_DETAIL(props.drop.id))
      }}
    />
  )
}

function MarketplaceView(props: DropItemProps) {
  const history = useHistory()
  const { tenant } = useTenant()
  return (
    <View
      {...props}
      tenant={tenant!}
      onClick={() => {
        history.push(
          TO_GALLERY(props.drop.galleryId!)(
            TO_MARKETPLACE_ASSET_DETAILS(props.drop as BaseAssetType)
          )
        )
      }}
    />
  )
}

export default function DropItemLogic(props: DropItemProps) {
  return 'marketplace' in props.drop ? (
    <MarketplaceView {...props} />
  ) : props.availability === 'open' && 'asset' in props.drop ? (
    <DropViewWithCounter {...props} />
  ) : 'asset' in props.drop ? (
    <DropView {...props} />
  ) : (
    <AssetView {...props} />
  )
}
