import { PromiEvent } from 'web3-core'

let _web3Provider: import('web3').default
async function getProvider() {
  const Web3 = (await import('web3')).default
  return _web3Provider || (_web3Provider = new Web3(window.ethereum as any))
}
export function dispatchAndWait(func: any) {
  const _func = func as PromiEvent<any>
  return new Promise<string>((r, rej) => {
    _func.on('transactionHash', (tX) => {
      r(tX)
    })
    _func.catch((e) => {
      rej(e)
    })
  })
}
export function watchTransactionHash(transactionHash: string) {
  return new Promise<void>((r, rej) => {
    let counter = 60000 / 2500
    const t = setInterval(async () => {
      if (await isTransactionMined(transactionHash)) {
        clearInterval(t)
        r()
      } else if (counter === 0) {
        clearInterval(t)
        rej('TIMEOUT')
      }
      counter--
    }, 2500)
  })
}
export async function isTransactionMined(transactionHash: string) {
  const provider = await getProvider()
  const transaction = await provider.eth.getTransactionReceipt(transactionHash)

  console.log("sajdas", transaction, provider)
  if (
    !transaction ||
    !transaction.blockHash ||
    transaction.status === undefined
  )
    return undefined // I still don't know if it's loaded
  else return !!transaction.status === true
}

export async function waitForConfirmation(tHash: string) {
  return new Promise<void>(async (r, rej) => {
    while (true) {
      const bought = await isTransactionMined(tHash)
      if (bought === undefined)
        await new Promise<void>((r) => setTimeout(() => r(), 2000))
      else {
        if (bought) r()
        else rej(new Error('TRANSACTION_FAILED'))
        break
      }
    }
  })
}

export async function sendAndWaitForConfirmation(func: any) {
  const tHash = await dispatchAndWait(func)
  await waitForConfirmation(tHash)
}

export function normalizePrice(price: number, decimals: number) {
  return price / Math.pow(10, decimals)
}

export function normalizeUnit(price: number, decimals: number) {
  return price * Math.pow(10, decimals)
}
