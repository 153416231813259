import darkThemeFactory from './mode/dark'
import lightThemeFactory from './mode/light'

export default lightThemeFactory('#6B3C22', '#6B3C22', '#6B3C22', {
  primary: 'Open Sans',
  secondary: 'TAN Nimbus',
})
export const defaultMode = 'light'
export const alternativeMode = darkThemeFactory(
  '#6B3C22',
  '#6B3C22',
  '#6B3C22',
  {
    primary: 'Open Sans',
    secondary: 'TAN Nimbus',
  }
)
