import { validateCPF } from 'core/helpers/validators'
import { FormField } from 'features/CVM88/Form/Form.types'
import { tO } from 'translate/i18n'

export const DOCUMENT_FIELD = <I extends string>(id: I) =>
  ({
    id,
    type: 'text',
    mask: '999.999.999-99',
    validator: (_cpf) => {
      if (!_cpf) return true
      const cpf = _cpf!.replace(/[^0-9]/g, '')
      return cpf.length === 11 && validateCPF(cpf!)
        ? true
        : tO('flowRequirements.steps.personalData.error.invalidCPF')
    },
  } satisfies FormField)

export const cleanFormObject = <O extends Object>(obj: O) => {
  for (let _answer in obj) {
    const answer = _answer as keyof typeof obj
    if (!obj[answer]) delete obj[answer]
  }
}
