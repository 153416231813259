import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import { DropType } from 'core/logic/drop/drop.types'
import RarumHeader from 'components/RarumHeader'
import DropDetails from 'pages/Authenticated/Checkout/DropDetails/'
import SafetyLabel from '../SafetyLabel'
import useStyles from './DropInfo.style'

interface DropInfoViewProps {
  drop?: DropType | null
  price?: number
  type?: string
}
const DropInfoView: React.FC<DropInfoViewProps> = ({ drop, price, type }) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <RarumHeader disableRarumText />
      </CardContent>
      <Divider className={classes.divider} />
      <CardContent className={classes.cardContent}>
        <SafetyLabel typeOrProvider={type} />
      </CardContent>
      <Divider className={classes.divider} />
      <CardContent className={classes.cardContent}>
        <Box display='flex' flexDirection='column' alignItems='flex-start' mt={2} mb={2}>
          <DropDetails vertical drop={drop} price={price} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default DropInfoView
