import { Paginable } from '@onepercentio/one-ui/dist/hooks/usePagination'
import { useGalleryDrops } from 'context/Gallery'
import { AssetType, MarketplaceAssetType } from 'core/logic/asset/asset.types'
import { OfferType } from 'core/logic/drop/drop.types'
import { GalleryType } from 'core/logic/gallery/gallery.types'

export type GalleryDetailsProps = {}
export enum GalleryDetailsSeeAllOption {
  MARKETPLACE,
  ASSETS,
}
export type GalleryDetailsViewProps = {
  gallery: GalleryType
  drops: Paginable<OfferType[], []>
  dropGroups: ReturnType<typeof useGalleryDrops>['groups']
  expiredDrops: Paginable<OfferType[], []>
  marketplaceAssets: Paginable<(MarketplaceAssetType | AssetType)[], []>
  onGoBack: () => void
  onSeeAll: (what: GalleryDetailsSeeAllOption) => void
}
