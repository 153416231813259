import React, {
  ElementRef,
  useInsertionEffect,
  useMemo,
  useRef,
} from 'react'
import Styles from './FeatureDetails.module.scss'
import { useFeatures } from 'openspace/pages/Authenticated/Home/v2/Home/Features/Features.hook'
import { useParams } from 'react-router-dom'
import FeatureHead, {
  FeatureHeadMode,
} from 'openspace/components/FeatureHead/FeatureHead'
import Container from 'openspace/components/Container/Container'
import BackButtonView from 'components/BackButton/BackButton.view'
import { i18n, t } from 'translate/i18n'
import RarumByOne from 'openspace/assets/svg/lazyLogos/RarumByOne'
import Text from '@onepercentio/one-ui/dist/components/Text'
import Button from '@onepercentio/one-ui/dist/components/Button'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import { ConceptDetailsShape } from 'openspace/translate/languages/pt'
import useSnapToViewport from '@onepercentio/one-ui/dist/hooks/ui/useSnapToViewport'
import { TO_FEATURE_DETAILS, TO_MAINPAGE } from 'core/modules/router'
import Chip from 'components/Chip/Chip.view'
import Scheduler, {
  SchedulerBanner,
} from 'openspace/components/Scheduler/Scheduler'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'

const FEATURES_ORDER = new Set()

/**
 * Details for a specified feature
 **/
export default function FeatureDetails() {
  const schedulerRef = useRef<ElementRef<typeof Scheduler>>(null)
  const conceptId = useParams<{ featureId: string }>().featureId
  const concepts = useFeatures()
  const concept = useMemo(() => {
    return concepts.find((c) => c.concept === conceptId)!
  }, [conceptId, concepts])
  const history = useCustomHistory()
  const details = i18n.t<'openspace', ConceptDetailsShape>(
    `featureDetails.details.${concept.concept}`,
    {
      returnObjects: true,
    }
  )
  const angles = useMemo(() => {
    return new Array(details.features.length)
      .fill(undefined)
      .map(() => Math.random() * 360)
  }, [details.features.length])

  const moreFeaturesRef = useSnapToViewport(0)

  useInsertionEffect(() => {
    FEATURES_ORDER.delete(conceptId)
    FEATURES_ORDER.add(conceptId)
  }, [conceptId])

  const sortedConcepts = useMemo(() => {
    if (FEATURES_ORDER.size === 0)
      for (let concept of concepts) FEATURES_ORDER.add(concept.concept)
    const ORDER = Array.from(FEATURES_ORDER)
    return concepts
      .filter((c) => c.concept !== concept.concept)
      .sort((cA, cB) => {
        return ORDER.indexOf(cA.concept) - ORDER.indexOf(cB.concept)
      })
  }, [concepts, concept.concept])

  return (
    <>
      <Scheduler ref={schedulerRef} />
      <Container>
        <Spacing size='32' />
        <BackButtonView
          onClick={() => history.goBackWithFallback(TO_MAINPAGE())}
          text={t('generic.goBack')}
        />
        <Container className={`${Styles.effect} dark`}>
          <FeatureHead feature={concept} mode={FeatureHeadMode.DETAILS} />
          <Text type='p20_v2'>{details.description}</Text>
          <RarumByOne />
        </Container>
        <Spacing size='32' />
        <Spacing size='32' />
        <Spacing size='32' />
        <Text type={FigmaTypo.H3}>
          {t('home.sections.features.mainFeatures')}
        </Text>
        <LabeledHR label={<></>} />
        <Spacing size='16' />
        <Container className={`${Styles.features} dark`}>
          {details.features.map((f, i) => (
            <div style={{ '--angle': `${angles[i]}deg` } as any}>
              <Text type={FigmaTypo.H3}>{f.name}</Text>
              <Text type={FigmaTypo.P16}>{f.description}</Text>
            </div>
          ))}
        </Container>
        <Spacing size='32' />
        <Spacing size='32' />
        <Text type={FigmaTypo.H3}>
          {t('featureDetails.useCases').toUpperCase()}
        </Text>
        <>
          {details.cases.map((c) => {
            return (
              <>
                <LabeledHR label={<></>}>
                  <>
                    <Spacing size='16' />
                    <div className={Styles.case}>
                      <div>
                        <Text type={FigmaTypo.H1}>{c.title}</Text>
                        <Spacing size='32' />
                        <Text type={FigmaTypo.H3}>{c.subtitle}</Text>
                        <Spacing size='16' />
                        <Text type={FigmaTypo.P16}>
                          {c.description}
                          <br />
                          <br />
                          <Button
                            onClick={() => window.open(c.link, '_blank')}
                            variant='transparent-link'
                            color='primary'>
                            {t('featureDetails.actions.visitCase')}
                          </Button>
                        </Text>
                        <br />
                        <br />
                        <Text type={FigmaTypo.H3}>
                          {t('featureDetails.highlights')}
                        </Text>
                        {c.highlights.map((h) => (
                          <p>
                            <Chip
                              variant='filled'
                              color='primary'
                              chipLabel={h}
                            />
                          </p>
                        ))}
                      </div>
                      <img src={c.image} alt={c.title} />
                    </div>
                  </>
                </LabeledHR>
              </>
            )
          })}
        </>

        <Spacing size='32' />
      </Container>

      <SchedulerBanner onSchedule={() => schedulerRef.current!.open()}/>
      <Spacing size='32' />

      <Container>
        <Text type={FigmaTypo.H3}>{t('featureDetails.more')}</Text>
        <LabeledHR label={<></>} />
        <Container className={Styles.moreFeatures}>
          <div ref={moreFeaturesRef}>
            {sortedConcepts.map((c) => (
              <div
                onClick={() => history.replace(TO_FEATURE_DETAILS(c.concept))}>
                <FeatureHead feature={c} mode={FeatureHeadMode.SMALL} />
              </div>
            ))}
          </div>
        </Container>
        <Spacing size='32' />
      </Container>
    </>
  )
}
