import copy from 'clipboard-copy'
import { SINGLE_TYPE } from 'core/constants'
import { CheckoutInstance } from 'core/helpers/juno'
import { usePurchase } from 'core/logic/purchase/purchase.hook'
import { analyticsEvent } from 'core/modules/analytics/events'
import { doPayment } from 'core/modules/firebase/service'
import {
  TO_DROP_OPEN,
  TO_DROP_OPENING,
  TO_MY_COLLECTION,
} from 'core/modules/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { IPaymentCreditCardInputs } from './PendingPayment.types'
import PaymentView from './PendingPayment.view'
import useQuery from '@onepercentio/one-ui/dist/hooks/utility/useQuery'
import { PurchaseType } from 'core/logic/purchase/purchase.types'

const PendingPaymentLogic = () => {
  const history = useHistory()
  const goToPackOpen = useCallback(
    (deliveryId: string, TO_DROP: (dId: string) => string) =>
      history.push(TO_DROP(deliveryId)),
    [history]
  )
  const onLeaveClickHandler = () => history.push(TO_MY_COLLECTION())
  const uuid = uuidv4()

  const { purchaseId } = useParams<{ purchaseId: string }>()
  const { purchase } = usePurchase({ purchaseId })
  const deliveryId = purchase?.deliveryId
  const drop = purchase?.offer
  const payed = useQuery<{ payed: boolean }>().get('payed')

  const _purchase = useMemo(() => {
    if (!purchase) return purchase
    return {
      ...purchase,
      paymentStatus: payed
        ? purchase.paymentStatus === 'ACTIVE'
          ? 'PROCESSING'
          : purchase.paymentStatus
        : purchase.paymentStatus,
    } as PurchaseType
  }, [purchase, payed])

  const gotToPackSingle = useCallback(
    (type: string, deliveryId: string) => {
      return type === SINGLE_TYPE
        ? goToPackOpen(deliveryId, TO_DROP_OPENING)
        : goToPackOpen(deliveryId, TO_DROP_OPEN)
    },
    [goToPackOpen]
  )

  // True while waiting for the credit card payment processing to complete
  const [doingPayment, setDoingPayment] = useState(false)
  // Holds a credit card payment process error message, when applicable
  const [paymentError, setPaymentError] = useState<string | null>()
  // Holds whether payment has already been confirmed or not
  const [paymentReceived, setPaymentReceived] = useState(false)

  /**
   * Do a credit card payment, and redirect to pack opening on success.
   * @param param0 Credit card data
   */
  const onPay = async ({
    userName,
    cardNumber,
    cvv,
    expiry,
  }: IPaymentCreditCardInputs) => {
    if (!userName || !cardNumber || !cvv || !expiry) {
      throw new Error('Missing required form fields')
    }
    try {
      setPaymentError(null)
      setDoingPayment(true)
      const checkout = new CheckoutInstance()
      const creditCardHash = await checkout.getCardHash({
        cardNumber,
        holderName: userName,
        securityCode: cvv,
        expiration: expiry,
      })
      if (drop) analyticsEvent.doPayment({ offer: drop })
      const { id } = await doPayment({
        purchaseId,
        tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
        creditCardHash,
      })
      setPaymentReceived(true)
      if (drop) {
        analyticsEvent.paymentConfirmed({ deliveryId: id, offer: drop })
        gotToPackSingle(drop.type, id)
      }
    } catch (err: any) {
      const message = err?.details?.join('\n') || err.message
      setPaymentError(message)
      analyticsEvent.error({ description: `purchases-payment: ${message}` })
    } finally {
      setDoingPayment(false)
    }
  }

  // Listens for the deliveryId being added to the purchase, goes to opening when it does
  useEffect(() => {
    if (deliveryId && !paymentReceived) {
      setPaymentReceived(true)
      if (drop) {
        analyticsEvent.paymentConfirmed({ deliveryId, offer: drop })
        gotToPackSingle(drop.type, deliveryId)
      }
    }
  }, [gotToPackSingle, drop, deliveryId, paymentReceived])

  return (
    <PaymentView
      drop={drop}
      onPay={onPay}
      onCopy={copy}
      purchase={_purchase}
      doingPayment={doingPayment}
      paymentError={paymentError}
      onMyCollection={onLeaveClickHandler}
      uuid={uuid}
    />
  )
}

export default PendingPaymentLogic
