import WalletRequiredWrapper from 'components/WalletRequiredWrapper'
import { useMarketplace } from 'context/Marketplace'
import { AssetType, AssetWithBalance } from 'core/logic/asset/asset.types'
import ROUTES, {
  TO_COLLECTION_ITEM_DETAIL,
  TO_MARKETPLACE_OFFER_DETAILS,
} from 'core/modules/router'
import { useState, useMemo, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { SettleOperationType } from '../SettleOperation/SettleOperation.types'
import SettleOperationLogic from '../SettleOperation/SettleOperation.logic'
import {
  CreateOfferFormMode,
  OfferForm,
  PREDEFINED_DEADLINES,
} from './CreateOffer.types'
import CreateOfferView from './CreateOffer.view'
import Transitions from 'components/Transitions'
import { useUser } from 'core/logic/user'
import { DeepPartial } from 'react-hook-form'
import { useRemainingAvailableTokens } from 'core/logic/token/token.hook'
import Confirm from './Confirm'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import { useIsWalletCustody } from 'context/Wallet'
import {
  useMyCollectionItems,
  useUpToDateMyCollectionItems,
} from 'context/MyCollection'

const INITIAL_FORM: OfferForm = {
  selectedDeadline: PREDEFINED_DEADLINES.A_MONTH,
  selectedQuantity: 1,
  amount: {},
}

const isAmountEdited = (original: OfferForm, newInfo: OfferForm) => {
  const currency = Object.keys(original.amount)[0]
  return original.amount[currency]?.amount !== newInfo.amount[currency]?.amount
}

const isQuantityEdited = (original: OfferForm, newInfo: OfferForm) => {
  return original.selectedQuantity !== newInfo.selectedQuantity
}

function CreateOfferLogic({
  onConfirm,
  onEdit,
  compact,
}: {
  onConfirm: (offer: OfferForm, offerId?: string) => void // When we are creating a new offer
  onEdit: (offer: DeepPartial<OfferForm>, offerId: string) => void // When we are editing an existing offer
  compact: boolean
  asset?: AssetWithBalance
}) {
  const { assetId: id, offerId } = useParams<{
    assetId: AssetType['id']
    offerId?: string
  }>()
  const { ownedItems } = useMyCollectionItems()

  const asset = useMemo(() => {
    return ownedItems?.find((a) => a.id === id)
  }, [ownedItems, id])
  const { currencies, tax, sales } = useMarketplace()
  const [offerForm, setDetails] = useState<OfferForm>(INITIAL_FORM)
  const { availableForSale } = useRemainingAvailableTokens(asset, offerId)
  const history = useCustomHistory()

  useEffect(() => {
    if (offerId) sales.getNextPage(id)
  }, [id, offerId])

  useEffect(() => {
    if (asset) {
      setDetails((prev) => ({
        ...prev,
        tokenId: asset.tokenId,
      }))
    }
  }, [asset, asset?.id])

  useEffect(() => {
    if (currencies.length === 1)
      setDetails((prev) => ({
        ...prev,
        amount: {
          [currencies[0]]: {
            checked: true,
            amount: undefined as any,
          },
        },
      }))
  }, [currencies])

  const origOffer = useMemo<OfferForm | undefined>(() => {
    return offerId && asset
      ? sales.items?.[asset.id]?.reduce(
          (r, i, index, arr) => {
            if (i.offerId === offerId) {
              if (!r.amount[i.currency]) r.amount[i.currency] = {} as any
              r.amount[i.currency]!.amount = i.price
              r.amount[i.currency]!.checked = true
              r.selectedQuantity++
            }

            if (index === arr.length - 1) {
              r.selectedQuantity =
                r.selectedQuantity / Object.keys(r.amount).length
            }
            return r
          },
          { ...INITIAL_FORM, selectedQuantity: 0 }
        )
      : undefined
  }, [asset, offerId, sales.items])

  const canContinue = useMemo(() => {
    const hasAnActiveCurrency = currencies.some(
      (curr) => offerForm.amount[curr]?.checked
    )
    const someCurrencyIsNegative = currencies.some(
      (curr) =>
        offerForm.amount[curr]?.checked && offerForm.amount[curr]!.amount < 0
    )
    const someCheckedMissingAmount = !!currencies.find(
      (curr) =>
        offerForm.amount[curr]?.checked && !offerForm.amount[curr]?.amount
    )
    const hasNecessaryInfo =
      hasAnActiveCurrency &&
      !someCheckedMissingAmount &&
      !someCurrencyIsNegative

    // Is an edition and has old information
    if (offerId && origOffer) {
      let isEditingSomeInfo =
        isAmountEdited(origOffer, offerForm) || // Has changed amount
        isQuantityEdited(origOffer, offerForm) // Or has changed amount
      return hasNecessaryInfo && isEditingSomeInfo
    }

    return hasNecessaryInfo
  }, [offerForm, origOffer, currencies, offerId])

  const onConfirmSellOrUpdate = () => {
    // Is edition?
    if (offerId) {
      const onlyEditedFields: DeepPartial<OfferForm> = {
        ...(isAmountEdited(origOffer!, offerForm)
          ? { amount: offerForm.amount }
          : {}),
        ...(isQuantityEdited(origOffer!, offerForm)
          ? { selectedQuantity: offerForm.selectedQuantity }
          : {}),
      }
      onEdit(onlyEditedFields, offerId)
    } else {
      onConfirm(offerForm, offerId)
    }
  }

  useEffect(() => {
    if (origOffer) setDetails(origOffer)
  }, [origOffer])

  const { ownedAssetDetails } = useMemo(() => {
    if (!offerId) {
      return {
        ownedAssetDetails: asset,
      }
    } else {
      return {
        ownedAssetDetails: origOffer ? asset : undefined,
      }
    }
  }, [origOffer, asset, offerId])

  useEffect(() => {
    if (availableForSale !== undefined && availableForSale <= 0) {
      history.replace(TO_COLLECTION_ITEM_DETAIL(asset!.id, asset!.tokenId))
    }
  }, [availableForSale, history, asset])

  return (
    <CreateOfferView
      key={'confirm_step'}
      onGoBack={() =>
        history.goBackWithFallback(
          TO_COLLECTION_ITEM_DETAIL(id, asset!.tokenId)
        )
      }
      baseAsset={ownedAssetDetails}
      loading={!ownedAssetDetails}
      form={offerForm}
      currencies={currencies}
      canContinue={canContinue}
      tax={tax}
      howManyItemsICanSell={availableForSale || 0}
      compact={compact}
      onUpdateCurrency={(curr, active, amount) =>
        setDetails((prev) => ({
          ...prev,
          amount: {
            [curr]: {
              checked: active,
              amount: amount,
            },
          },
        }))
      }
      onUpdateDeadline={(dl) =>
        setDetails((prev) => ({ ...prev, selectedDeadline: dl }))
      }
      onUpdateQuantity={(dl) =>
        setDetails((prev) => ({ ...prev, selectedQuantity: dl }))
      }
      onSell={onConfirmSellOrUpdate}
      mode={
        !offerId ? CreateOfferFormMode.CREATION : CreateOfferFormMode.EDITION
      }
    />
  )
}

export default function CreateOfferLogicWrapper({
  compact = false,
}: {
  compact?: boolean
}) {
  const isWalletCustody = useIsWalletCustody()
  const { assetId: id } = useParams<{
    assetId: AssetType['id']
    offerId?: string
  }>()
  const { ownedItems } = useUpToDateMyCollectionItems()

  const asset = useMemo(() => {
    return ownedItems?.find((a) => a.id === id)
  }, [ownedItems, id])
  const history = useCustomHistory()
  const [offerDetails, setOfferDetails] = useState<
    (OfferForm | DeepPartial<OfferForm>) & { offerId?: string }
  >()
  const { profile } = useUser()
  const content = useMemo(() => {
    if (profile && profile.isLoaded && isWalletCustody) {
      return (
        <Redirect key='redirect' to={ROUTES.authenticated.transferTokens} />
      )
    } else if (!offerDetails) {
      return (
        <CreateOfferLogic
          compact={compact}
          key={'details'}
          onEdit={(details, offerId) => {
            setOfferDetails({
              ...details,
              offerId,
            })
          }}
          asset={asset}
          onConfirm={(details) =>
            setOfferDetails({
              ...details,
            })
          }
        />
      )
    } else {
      return (
        <WalletRequiredWrapper key='confirmation'>
          {!offerDetails.offerId ? (
            <Confirm asset={asset!} offer={offerDetails as OfferForm} />
          ) : (
            <SettleOperationLogic
              type={SettleOperationType.MARKETPLACE_ITEM_EDITION}
              params={[offerDetails.offerId, offerDetails as OfferForm]}
              onBack={() =>
                history.goBackWithFallback(
                  TO_COLLECTION_ITEM_DETAIL(id, asset!.tokenId)
                )
              }
              onFinish={(offerId) =>
                history.replace(TO_MARKETPLACE_OFFER_DETAILS(id, offerId!))
              }
            />
          )}
        </WalletRequiredWrapper>
      )
    }
  }, [offerDetails, compact, asset, history, id, profile])
  return <Transitions>{content}</Transitions>
}
