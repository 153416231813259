import Box, { BoxProps } from '@material-ui/core/Box'
import React, { memo } from 'react'
import useStyles from './ClientLogo.style'
interface ClientLogoViewProps {
  clientLogoSrc?: string
}
const ClientLogoView: React.FC<BoxProps & ClientLogoViewProps> = ({clientLogoSrc, ...props}) => {
  const classes = useStyles()
  return (
    <Box display='flex' flexDirection='row' alignItems='center' {...props}>
      <img
        src={clientLogoSrc || `${process.env.PUBLIC_URL}/logo.svg`}
        alt=''
        className={classes.logo}
      />
    </Box>
  )
}

export default memo(ClientLogoView)
