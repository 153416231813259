import React, { PropsWithChildren, ReactElement } from 'react'
import { PaymentViewProps } from './Payment.types'
import DestinatedOfferInfo from 'components/DestinatedOfferInfo'
import RarumAdaptiveSidebar from 'components/RarumAdaptiveSidebar/RarumAdaptiveSidebar'
import Control from 'components/RarumAdaptiveSidebar/Control'
import Text from 'openspace/components/Text'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import RarumHeader from 'components/RarumHeader'
import SafetyLabel from '../SafetyLabel'
import Container from 'openspace/components/Container'
import Step, { StepsWrapper } from 'features/CVM88/Form/Step/Step'
import { DataStatus, StepStatus } from 'features/CVM88/Form/Form.types'
import Styles from './Payment.module.scss'
import BackButtonView from 'components/BackButton/BackButton.view'
import { t } from 'translate/i18n'

/**
 * A modern implementation of the Payment flow that supports requirements for offers and payment methods
 **/
export default function PaymentView({
  paymentMethod,
  offer,
  children,
  onBack,
}: PaymentViewProps) {
  return (
    <>
      <Container>
        <BackButtonView onClick={() => onBack()} text={t('generic.goBack')} />
      </Container>
      <Container className={Styles.root}>
        <div className={Styles.main}>
          <Text code='authenticated.payment.purchaseData' type='h2' />
          <Spacing size='40' />
          <Text code='authenticated.payment.fillInTheData' type='content' />
          {children as ReactElement}
        </div>

        <div>
          <RarumAdaptiveSidebar
            breakInto={1200}
            visibilityControlComponent={Control}
            className={Styles.offer}>
            <div className={Styles.offerCard}>
              <RarumHeader disableRarumText />
              <SafetyLabel typeOrProvider={paymentMethod?.[0]} />
              <Spacing size='32' />
              <DestinatedOfferInfo
                direction='v'
                assetOrOffer={offer}
                isSale={false}
              />
            </div>
          </RarumAdaptiveSidebar>
        </div>
      </Container>
    </>
  )
}

export function PaymentFlowView({
  steps: { all, current },
  children,
}: PropsWithChildren<{
  steps: {
    current: number
    all: { title: string }[]
  }
}>) {
  return (
    <>
      <Spacing size='32' />
      <StepsWrapper
        className={Styles.heading}
        currStep={current}
        steps={all.length}>
        {all.map((step, idx) => (
          <Step
            key={String(idx)}
            dataStatus={
              idx < current ? DataStatus.COMPLETE : DataStatus.PENDING
            }
            stepName={step.title}
            stepNumber={idx + 1}
            stepStatus={
              step === all[current] ? StepStatus.CURRENT : StepStatus.COMPLETED
            }
          />
        ))}
      </StepsWrapper>
      <Spacing size='32' />
      {children as ReactElement}
    </>
  )
}
