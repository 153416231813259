import React, { ReactNode } from 'react'
import { AddressFormMode, AddressFormViewProps } from './AddressForm.types'
import Styles from './AddressForm.module.scss'
import {
  AnswerAction,
  AnswerByField,
  FormFieldView,
} from 'features/CVM88/Form/Form.types'
import { AddressFormField } from './AddressForm.types'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import Select from '@onepercentio/one-ui/dist/components/Select/Select'
import { AnchoredTooltipAlignment } from '@onepercentio/one-ui/dist/components/AnchoredTooltip/AnchoredTooltip'
import InputMask from 'react-input-mask'
import Input from '@onepercentio/one-ui/dist/components/Input'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { t } from 'translate/i18n'
import { AddressChangeAction } from './AddressForm.types'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Card from 'components/Card/Card'

export function AddressFormFieldInput<Q extends AddressFormField>({
  config: c,
  onAnswer,
  value,
  mode,
  error,
}: {
  config: Q
  value: AnswerByField<Q>
  onAnswer: AddressChangeAction<Q>
  mode: AddressFormMode
  error?: string
}) {
  const val = <T extends FormFieldView['type']>() =>
    value as unknown as
      | AnswerByField<{
          type: T
        }>
      | undefined
  const ans = <T extends FormFieldView['type']>(
    answer: AnswerAction<{
      type: T
    }>
  ) =>
    answer as unknown as AnswerAction<{
      type: T
    }>
  const type = c.type
  if (mode === AddressFormMode.READ_ONLY) {
    return (
      <>
        <Card>
          <OneText type={FigmaTypo.H4}>{c.title}</OneText>
          <OneText type={FigmaTypo.P16}>{(value as string) || '-'}</OneText>
        </Card>
        <Spacing size='16' />
      </>
    )
  }
  switch (type) {
    case 'select':
      return (
        <>
          <OneText type='p12'>{c.title}</OneText>
          <Select
            alignTo={AnchoredTooltipAlignment.LEFT}
            items={c.options}
            selected={val<typeof type>()!}
            onClick={(i) => {
              onAnswer(type, c.id, i.value)
            }}
            filter={c.filter}
            disabled={c.disabled}
            label={c.title}
            error={error}
          />
        </>
      )
    case 'text':
    case 'number':
      const filter =
        type === 'number'
          ? (val: string) => val.replace(/[^0-9]+/g, '')
          : (val: string) => val
      return (
        <>
          <OneText type='p12'>
            {c.title}
            {c.optional ? ` (${t('generic.optional')})` : null}
          </OneText>
          {type === 'text' && c.mask ? (
            <InputMask
              disabled={c.disabled}
              mask={c.mask}
              value={val<typeof type>()}
              onChange={({ target: { value } }) => {
                const answer = ans<typeof type>(value)
                onAnswer(type, c.id, answer)
              }}>
              {
                ((inputProps: any) => (
                  <Input
                    {...inputProps}
                    placeholder={c.title}
                    error={
                      c.error ? (
                        <Button
                          className={Styles.error}
                          onClick={c.error.retry}
                          variant='transparent-link'>
                          {c.error.label}
                        </Button>
                      ) : (
                        error
                      )
                    }
                  />
                )) as unknown as ReactNode
              }
            </InputMask>
          ) : (
            <Input
              disabled={c.disabled}
              placeholder={c.title}
              value={val<typeof type>()}
              onChange={({ target: { value } }) => {
                onAnswer(type, c.id, filter(value))
              }}
              error={error}
            />
          )}
        </>
      )
    default:
      throw new Error('Not implemented yet')
  }
}

/**
 * Allows the user to easily search for an address
 **/
export default function AddressFormView({
  onAddressFieldChange,
  address,
  mode,
  errors,
  fields
}: AddressFormViewProps) {
  return (
    <>
      {fields.map((question) => (
        <AddressFormFieldInput
          config={question}
          onAnswer={onAddressFieldChange}
          value={address[question.id as 'zipcode']}
          mode={mode}
          error={errors[question.id]}
        />
      ))}
    </>
  )
}
